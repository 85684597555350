import React, { useState, useEffect, useContext } from "react";
import DJDesempleo from "./componentes/dj_desempleo/index.jsx";
import DJBono from "./componentes/dj_no_bono/index.jsx";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import Loading from "../../../../../../components/Loading/index.jsx";
import ModalDeErrorGeneral from "../../../../Components/ModalDeErrorGeneral";

const Paso3 = (props) => {
  const [showView, setShowView] = useState(0);
  const [haveBono, sethaveBono] = useState(false);
  const [objDesempleo, setObjDesempleo] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [contents, setContents] = useState([]);
  const [dependencies, setDependencies] = useState({
    cussp: "",
    full_name: "",
    type_and_number_of_document: "",
  });

  const jubilacionContext = useContext(JubilacionContext);
  const {
    temasAsesoriaRejaLoading,
    temasAsesoriaRejaData,
    obtenerContenidoPasoReja,
    limpiarObtenerContenidoPasoReja,
    mensajeAlerta,
  } = jubilacionContext;

  useEffect(() => {
    window.scrollTo(0, 0);
    obtenerContenidoPasoReja(props.processId, 3);
    return () => {
      limpiarObtenerContenidoPasoReja();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showView]);

  useEffect(() => {
    if (temasAsesoriaRejaData) {
      if (temasAsesoriaRejaData.success) {
        if (temasAsesoriaRejaData.step === 3) {
          props.setCurrectStepTitle(temasAsesoriaRejaData.name);
          setContents(temasAsesoriaRejaData.contents);
          sethaveBono(temasAsesoriaRejaData.dependencies.has_a_bonus);
          const status = temasAsesoriaRejaData.contents.filter(
            (content) => content.status === "finished"
          );

          setDependencies(temasAsesoriaRejaData.dependencies);
          if (status.length === 0) {
            setShowView(1);
          } else {
            if (status[0].status === "finished") {
              setShowView(2);
            } else {
              setShowView(1);
            }
          }
        }
      } else {
        setShowModal(!showModal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temasAsesoriaRejaData]);

  const renderView = () => {
    switch (showView) {
      case 1:
        return (
          <DJDesempleo
            idProcess={props.processId}
            idContent={
              contents.find((content) => content.slug === "step-3.1")
                ? contents.find((content) => content.slug === "step-3.1").id
                : null
            }
            haveBono={haveBono}
            dependencies={dependencies}
            setObjDesempleo={(item) => setObjDesempleo(item)}
            showBonoView={() => setShowView(2)}
            endThirdStep={() => props.setCurrectStepNumber(4)}
            getOutOfProcess={() => props.getOutOfProcess()}
          />
        );
      case 2:
        return (
          <DJBono
            idProcess={props.processId}
            objDesempleo={objDesempleo}
            idContent={
              contents.find((content) => content.slug === "step-3.2")
                ? contents.find((content) => content.slug === "step-3.2").id
                : null
            }
            dependencies={dependencies}
            endThirdStep={() => props.setCurrectStepNumber(4)}
            showVistaView={() => setShowView(3)}
            getOutOfProcess={() => {
              props.getOutOfProcess();
            }}
          />
        );
      //    case 3:
      //    return <VistaEspera
      //         imgLogo={imgConfirmation}
      //            title="Hemos concluido con éxito tu solicitud"
      //         description={
      //             <>
      //                 <strong>{sessionStorage.getItem("nombreUsuario")}</strong>, hemos concluido con éxito la primera etapa de tu solicitud.
      //                 <br /> <br />
      //                 En un plazo de 7 días útiles podrás ingresar de nuevo a esta opción para elegir tu modalidad de pensión.
      //             </>
      //         }
      //         // btnLabel={`${estimacionRejaLoading ? 'Consultando... ' : 'Continuar'}`}
      //         // btnFunction={() => setShowView(2)}
      //         // loading={estimacionRejaLoading}
      //         // disable={estimacionRejaLoading}
      //         btnFunction={() => props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion")}
      //     />
      default:
        return <Loading />;
    }
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (temasAsesoriaRejaLoading) return <Loading />;
  return (
    <div>
      {renderView()} {showModal && <ModalDeErrorGeneral />}
    </div>
  );
};

export default Paso3;
