import React, { useEffect, useState, useContext } from "react";
import {
  ContainerView,
  ContentTitle,
  Contents,
  ContentsLabel,
} from "../../styles/ViewComponent";
import { Alert, Grid } from "@mui/material";
import Formulario from "../../../../../../assets/icons/formulario.svg";
import Icon from "../components/Icon/index.jsx";
import Appbar from "../components/Appbar/index.jsx";
import Page from "../components/Page/index.jsx";
import useWindowSize from "../../../../../../hooks/useWindowSize";
import { size } from "../../../../../../utils/device";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { useHistory } from "react-router-dom";
import { FormGroup, Input, Label } from "reactstrap";
import { Button } from "../../../../../../components/index.js";
import clienteAxios from "../../../../../../config/axios.js";
import CustomUpdateDataModal from "../../../../Components/customUpdateDataModal/CustomModal.jsx";
import CustomDataModal from "../../../../Components/customDataModal/CustomModal.jsx";
import CustomComercialDataModal from "../../../../Components/customComercialDataModal/CustomModal.jsx";
import ModalToken from "../../../../../../components/ModalToken/index.jsx";
import ModalTokenError from "../../../../../../components/ModalTokenError/index.jsx";

import MisdatosContext from "../../../../../../context/misdatos/misdatosContext";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import AsesorContext from "../../../../../../context/asesor/asesorContext";
import HomeContext from "../../../../../../context/home/homeContext.jsx";

import "./index.css";

const globalErrors = {
  status_celular: false,
  status_correo: false,
  l_celular: "",
  l_correo: "",
};

const Paso0 = () => {
  const initForm = {
    l_celular: "",
    l_correo: "",
  };
  const finalForm = {
    l_celular: "",
    l_correo: "",
    l_autorizacion_dp: "",
  };

  const history = useHistory();
  const [showStepComponent, setShowStepComponent] = useState(true);

  const [formulario, setFormulario] = useState(initForm);
  const [finalFormulario, setFinalFormulario] = useState(finalForm);
  const [errores, setErrores] = useState(globalErrors);
  const [loading, setLoading] = useState(false);
  const [isValidFields, setIsValidFields] = useState(false);
  const [isValidData, setIsValidData] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isSuccessValidation, setIsSuccessValidation] = useState(false);
  const [isErrorValidation, setIsErrorValidation] = useState(false);
  const [finalStep, setFinalStep] = useState("Continuar");
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [openModalToken, setOpenModalToken] = useState(false);
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [showModalComercialInfo, setShowModalComercialInfo] = useState(false);
  const [mostrarModalToken, setMostrarModalToken] = useState(null);

  const misdatosContext = useContext(MisdatosContext);
  const {
    misDatosGet,
    obtenerMisDatosGet,
    actualizarDatosPost,
    actualizarDatosProcesos,
    resultadoDatosPost,
  } = misdatosContext;

  const jubilacionContext = useContext(JubilacionContext);
  const { registerDataComercial, sendMailComercial } = jubilacionContext;
  const asesorContext = useContext(AsesorContext);
  const { encontroAsesor, ejecutivo, obtenerAsesor } = asesorContext;
  const homeContext = useContext(HomeContext);
  const { fondos, obtenerDatosHome } = homeContext;

  const { width } = useWindowSize();
  const isSmallScreen = width <= size.tablet;
  const AppbarComponent = showStepComponent
    ? isSmallScreen
      ? true
      : false
    : false;

  const client = JSON.parse(localStorage.getItem("client"));
  const handlerNumeros = (e) => {
    const valor = e.target.validity.valid ? e.target.value : "";
    if (isValidData && !isSuccessValidation) {
      if (e.target.value.length !== formulario.l_celular.length) {
        setIsValidData(false);
        setIsErrorValidation(false);
      }
    }
    setFormulario({ ...formulario, [e.target.name]: valor });
    if (valor.length < 9) {
      setErrores({
        ...errores,
        l_celular: "El número de celular debe tener 9 dígitos",
      });
    } else {
      setErrores({ ...errores, l_celular: "" });
    }
  };

  const handlerEmail = (e) => {
    const expresionRegular = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (isValidData && !isSuccessValidation) {
      if (e.target.value.length !== formulario.l_correo.length) {
        setIsValidData(false);
        setIsErrorValidation(false);
      }
    }
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value.toUpperCase(),
    });
    if (!expresionRegular.test(e.target.value)) {
      setErrores({ ...errores, l_correo: "Correo electrónico inválido" });
    } else {
      setErrores({ ...errores, l_correo: "" });
    }
  };

  const handlerDisable = (e) => {
    e.preventDefault();
  };

  const handleValidateData = async () => {
    try {
      setIsValidating(true);
      setLoading(true);
      let datos = {
        r_celular: formulario.l_celular,
        r_correo: formulario.l_correo,
        r_doc_num: localStorage.getItem("doc_num").toString(),
        r_doc_type: localStorage.getItem("doc_type").toString(),
      };

      let dataComercial = {
        documentType: localStorage.getItem("doc_type").toString(),
        document: localStorage.getItem("doc_num").toString(),
        typeRetirement: "REJA",
      };

      let response = await clienteAxios.post("/validar-datos", datos);
      if (response.data.r_sesion == true) {
        setIsValidData(true);
        setIsSuccessValidation(true);
        setFinalStep("Continuar");
        await actualizarDatosProcesos(true);
        await registerDataComercial(dataComercial);
        localStorage.setItem("isDataUpdated", true);
        localStorage.setItem("firstUpdateData", true);
        setErrores({
          ...errores,
          status_celular: false,
          status_correo: false,
        });
      } else {
        setIsValidData(true);
        setIsErrorValidation(true);
        setFinalStep("Actualizar datos");
        setErrores({
          ...errores,
          status_celular: !response.data.r_errores.r_celular,
          status_correo: !response.data.r_errores.r_email,
        });
      }
    } catch (error) {
      console.log("ERROR", error);
      setIsValidating(false);
      setLoading(false);
    } finally {
      setIsValidating(false);
      setLoading(false);
    }
  };

  const getOutOfProcess = () => {
    history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion");
  };

  const getComercialStatus = () => {
    if (encontroAsesor) {
      return true;
    } else {
      let fondosStorage = JSON.parse(sessionStorage.getItem("fondos"));
      const encontrarOBL = fondosStorage.find(
        (item) => item.r_cuenta === "OBL"
      );
      if (encontrarOBL) {
        if (parseFloat(encontrarOBL.r_saldo) >= 100000) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  const handleOpenModalUpdateData = () => {
    if (finalStep === "Continuar") {
      let showComercialModal = getComercialStatus();
      if (showComercialModal) {
        setShowModalComercialInfo(true);
      } else {
        history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion");
      }
    } else {
      setShowModalConfirmation(true);
    }
  };

  const handleSaveDataUser = (data, isChecked) => {
    setFinalFormulario({
      l_celular: data.l_celular,
      l_correo: data.l_correo,
      l_autorizacion_dp: isChecked ? "1" : "0",
    });
    setShowModalConfirmation(false);
    setShowModalInfo(true);
  };

  const procesarSolicitud = async (token) => {
    let info = {
      data: {
        r_celular: finalFormulario.l_celular,
        r_correo: finalFormulario.l_correo,
        r_autorizacion_dp: finalFormulario.l_autorizacion_dp,
      },
      v_token_digital: token,
    };

    let dataComercial = {
      documentType: localStorage.getItem("doc_type").toString(),
      document: localStorage.getItem("doc_num").toString(),
      typeRetirement: "REJA",
    };

    let response = await actualizarDatosPost(info);
    if (response.status == "200" && response.data.r_respuesta !== -3) {
      setIsSuccessUpdate(true);
      setOpenModalToken(false);
      setShowModalInfo(true);
      await actualizarDatosProcesos(true);
      await registerDataComercial(dataComercial);
      localStorage.setItem("isDataUpdated", true);
      localStorage.setItem("firstUpdateData", true);
      localStorage.setItem("mailUpdated", finalFormulario.l_correo);
    }
  };

  const handleRedirect = () => {
    let showComercialModal = getComercialStatus();
    if (showComercialModal) {
      setShowModalInfo(false);
      setShowModalComercialInfo(true);
    } else {
      history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion");
    }
  };

  const handleRedirectToJubilacion = async () => {
    try {
      let data;
      if (encontroAsesor) {
        data = {
          asesor: "1",
          cuspp: client.r_cuspp,
          asesor_sexo: ejecutivo ? ejecutivo.r_sexo : "",
          primer_nombre_cap: client.r_primer_nombre,
          tipo_jubilacion: "Jubilación Anticipada por Desempleo (REJA)",
          asesor_nombre: ejecutivo ? ejecutivo.r_nombreAbreviado : "",
          asesor_sujeto: ejecutivo?.r_sexo === "F" ? "ella" : "él",
          asesor_celular: ejecutivo ? ejecutivo.r_celular : "",
          asesor_correo: ejecutivo ? ejecutivo.r_correo : "",
          informado: client.gender === "F" ? "informada" : "informado",
          usuario_correo: localStorage.getItem("mailUpdated")
            ? localStorage.getItem("mailUpdated").toString()
            : client.r_correo,
        };
      } else {
        data = {
          asesor: "0",
          cuspp: client.r_cuspp,
          tipo_jubilacion: "Jubilación Anticipada por Desempleo (REJA)",
          primer_nombre_cap: client.r_primer_nombre,
          informado: client.gender === "F" ? "informada" : "informado",
          usuario_correo: localStorage.getItem("mailUpdated")
            ? localStorage.getItem("mailUpdated").toString()
            : client.r_correo,
        };
      }
      await sendMailComercial(data);
      history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion");
    } catch (error) {
      console.log(error);
    } finally {
      localStorage.removeItem("mailUpdated");
    }
  };

  useEffect(() => {
    if (
      formulario.l_celular !== "" &&
      formulario.l_correo !== "" &&
      errores.l_celular === "" &&
      errores.l_correo === ""
    ) {
      setIsValidFields(true);
    } else {
      setIsValidFields(false);
    }
  }, [initForm]);

  useEffect(() => {
    if (resultadoDatosPost) {
      if (resultadoDatosPost.r_respuesta === -3) {
        setMostrarModalToken(resultadoDatosPost);
        setOpenModalToken(false);
      } else {
        setMostrarModalToken(null);
        setOpenModalToken(false);
      }
    }
  }, [resultadoDatosPost]);

  useEffect(() => {
    obtenerDatosHome();
    obtenerAsesor();
    getComercialStatus();
    obtenerMisDatosGet();
  }, []);

  return (
    <Page>
      {AppbarComponent && (
        <Appbar
          title="Beneficios y jubilación"
          onHandleClick={getOutOfProcess}
        />
      )}
      <ContainerView>
        <ContentTitle>
          <Grid container>
            <Grid item xs={3} md={1}>
              <Icon url={Formulario} width={"64px"} height={"64px"} />
            </Grid>
            <Grid
              item
              xs={9}
              md={11}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                style={{
                  fontSize: "22px",
                  fontWeight: 900,
                  lineHeight: "32px",
                }}
              >
                Jubilación Anticipada por Desempleo (REJA)
              </span>
            </Grid>
          </Grid>
        </ContentTitle>
        <Grid container>
          <Grid item xs={12} md={12} className="HBT_JUBLEGAL_Divider">
            <Contents>
              <h4>Importante</h4>
              <p>
                Te solicitamos que ingreses tu número de celular y dirección de
                correo electrónico actualizados, ya que serán necesarios para el
                registro de esta solicitud.
              </p>
            </Contents>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContentsLabel>
              <FormGroup>
                <Label htmlFor="l_celular">Celular</Label>
                <Input
                  placeholder="Ingresa tu celular"
                  name="l_celular"
                  autoComplete="off"
                  value={formulario.l_celular}
                  pattern="[0-9]*"
                  onChange={handlerNumeros}
                  onPaste={handlerDisable}
                  onCopy={handlerDisable}
                  maxLength={9}
                  bsSize="lg"
                  style={{ marginBottom: "7px" }}
                  className={errores.status_celular ? "error_txt" : ""}
                  invalid={errores.status_celular}
                />
                {errores.l_celular && (
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#bf0d3e",
                    }}
                  >
                    {errores.l_celular}
                  </span>
                )}
              </FormGroup>
            </ContentsLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContentsLabel>
              <FormGroup>
                <Label htmlFor="l_correo">Correo electrónico</Label>
                <Input
                  placeholder="Ingresa tu correo electrónico"
                  name="l_correo"
                  autoComplete="off"
                  onChange={handlerEmail}
                  onPaste={handlerDisable}
                  onCopy={handlerDisable}
                  value={formulario.l_correo}
                  style={{ marginBottom: "7px" }}
                  className={errores.status_correo ? "error_txt" : ""}
                  invalid={errores.status_correo}
                  bsSize="lg"
                />
                {errores.l_correo && (
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#bf0d3e",
                    }}
                  >
                    {errores.l_correo}
                  </span>
                )}
              </FormGroup>
            </ContentsLabel>
          </Grid>
          <Grid item xs={12} md={12}>
            <ContentsLabel>
              {isSuccessValidation && (
                <Alert
                  iconMapping={{
                    success: <CheckCircleOutlineIcon fontSize="inherit" />,
                  }}
                >
                  Tus datos fueron validados correctamente.
                </Alert>
              )}
              {isErrorValidation && (
                <Alert
                  severity="error"
                  iconMapping={{
                    success: <ErrorOutlineIcon fontSize="inherit" />,
                  }}
                >
                  Los datos ingresados no coinciden con los registrados en tu
                  cuenta.
                </Alert>
              )}
            </ContentsLabel>
          </Grid>
          <Grid item xs={0} md={9}></Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: { xs: "flex" },
              alignItems: { xs: "center" },
              marginBottom: { xs: "20px" },
              paddingRight: { xs: "0px", md: "16px" },
            }}
          >
            <Button
              onClick={
                isValidData
                  ? () => handleOpenModalUpdateData()
                  : () => handleValidateData()
              }
              disabled={!isValidFields}
              style={{ width: "100%" }}
              loading={loading}
            >
              {isValidData ? finalStep : isValidating ? "Validando" : "Validar"}
            </Button>
          </Grid>
        </Grid>
      </ContainerView>
      {showModalConfirmation && (
        <CustomUpdateDataModal
          showModal={showModalConfirmation}
          className="beauty-modal"
          toggle={() => setShowModalConfirmation(!showModalConfirmation)}
          fnCloseIconBtn={() =>
            setShowModalConfirmation(!showModalConfirmation)
          }
          title="Actualización de datos"
          textBtnPrimary="Actualizar datos"
          btnPrimaryLoading={true}
          globalErrors={errores}
          labelFormulario={formulario}
          fnButtonPrimary={(data, check) => handleSaveDataUser(data, check)}
          isActiveTreatment={misDatosGet?.r_persona.r_datos.r_tratamiento_datos}
        />
      )}

      {showModalComercialInfo && (
        <CustomComercialDataModal
          showModal={showModalComercialInfo}
          fnCloseIconBtn={() => {
            setShowModalComercialInfo(!showModalComercialInfo);
          }}
          fnBtnPrimary={() => handleRedirectToJubilacion()}
          isCorrectlyUpdated={isSuccessUpdate}
          dataAsesor={encontroAsesor ? ejecutivo : null}
        />
      )}

      <CustomDataModal
        showModal={showModalInfo}
        className="beauty-modal"
        backdrop={"static"}
        fnCloseIconBtn={() => {
          setShowModalInfo(!showModalInfo);
        }}
        title="Confirmación de actualización de datos personales"
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          setOpenModalToken(true);
          setShowModalInfo(false);
        }}
        textBtnPrimary="Aceptar"
        hasBtnSecondary={true}
        fnBtnSecondary={() => {
          setShowModalConfirmation(true);
          setShowModalInfo(false);
        }}
        textBtnSecondary="Cancelar"
        formulario={finalFormulario}
        isCorrectlyUpdated={isSuccessUpdate}
        fnBtnContinuar={() => {
          handleRedirect();
        }}
      />
      {openModalToken && (
        <ModalToken
          showModal={openModalToken}
          cerrarModal={() => {
            setOpenModalToken(false);
          }}
          guardarCode={procesarSolicitud}
        />
      )}
      {mostrarModalToken && (
        <ModalTokenError
          mensaje={mostrarModalToken.r_mensaje}
          cerrarModal={() => {
            setMostrarModalToken(false);
          }}
          guardarCode={() => {
            setMostrarModalToken(false);
            setOpenModalToken(true);
          }}
        />
      )}
    </Page>
  );
};

export default Paso0;
