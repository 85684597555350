import React, { useEffect } from "react";
import { VistaEspera } from "../jubilacionReja/etapas/components";
import imgConfirmation from "../../../../assets/recuerda.svg";

export default function SegundoRetiroFin(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <VistaEspera
        imgLogo={imgConfirmation}
        title={`${sessionStorage.getItem(
          "nombreUsuario"
        )}, no hemos concluido con tu solicitud de segundo retiro de hasta el 95.5%`}
        description={<>Hubo un problema en tu registro </>}
        note={
          <>
            Si la información no es correcta, le solicitamos comunicarse con
            nuestro Call Center al 01 230-2200 Lima o al 0801-14-224 provincias
            para mayor detalle.{" "}
          </>
        }
        btnLabel="Continuar"
        btnFunction={() =>
          props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion")
        }
      />
    </div>
  );
}
