import React, { useState, useEffect, useContext, Fragment } from "react";
import AuthContext from "../../context/autenticacion/authContext";

import { Box, useTheme, useMediaQuery, IconButton } from "@mui/material";

import hocVariables from "../../HOC/HocVariables";
import "./index.css";

const TimmerHook = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery("(max-width:980px)");
  //Extraemos los valores del context
  const authContext = useContext(AuthContext);
  const { timer, reset, resetearTimer, cerrarSesion } = authContext;

  const { setMessage, limpiarVariablesContext } = props;

  const localTimer = timer ? timer : 5;
  // const localTimer = 99;

  const [minutes, setMinutos] = useState(localTimer);
  const [seconds, setSegundos] = useState(0);

  useEffect(() => {
    let myInterval = null;

    myInterval = setInterval(() => {
      if (seconds > 0) {
        setSegundos((seconds) => seconds - 1);
        if (reset) {
          setMinutos((minutes) => localTimer);
          setSegundos((seconds) => 0);
        }
      }
      if (seconds === 0) {
        if (minutes === 0) {
          cerradoAutomatico();
          clearInterval(myInterval);
        } else {
          setMinutos((minutes) => minutes - 1);
          setSegundos((seconds) => 59);
        }
      }
      if (minutes === 0 && seconds === 59) {
        abrirModalAutomatico();
      }
    }, 1000);

    if (reset) {
      resetearTimer();
    }

    return () => clearInterval(myInterval);
    // eslint-disable-next-line
  }, [seconds, minutes, timer]);
  const cerradoAutomatico = () => {
    sessionStorage.removeItem("token");
    cerrarSesion(false);
    limpiarVariablesContext();
  };

  const abrirModalAutomatico = () => {
    //DATALAYER SESION POR EXPIRAR
    window.dataLayer.push({
      event: "modal_view",
      description: "sesion a punto de expirar",
    });
    setMessage({
      msg: "Su sesión está apunto de expirar, ¿Desea continuar aquí?",
      code: 3,
    });
  };

  return (
    minutes !== null &&
    minutes >= 0 && (
      <Box
        className="m-0 timer-text"
        sx={{
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.2px",
          color: "#bf0d3e",
        }}
      >
        <span className="fa fa-clock-o">&nbsp; </span>
        {minutes === 0 && seconds === 0 ? (
          <span>tiempo agotado</span>
        ) : (
          <Fragment>
            {matches ? (
              ""
            ) : (
              <>
                <span className="text-clock">{" Su sesión finaliza en "}</span>
              </>
            )}

            <span>
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </span>
          </Fragment>
        )}
      </Box>
    )
  );
};

export default hocVariables(TimmerHook);
