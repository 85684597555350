import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    useMediaQuery,
    Slide,
    Divider
} from "@mui/material";
import CheckIcon from "../../../../assets/check_jub.svg"; // Ruta al ícono personalizado
import { useTheme } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

const ModalSolicitudExitosa = ({ open, onClose, title, description, buttonText, onButtonClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detecta si es móvil

    if (isMobile) {
        return (
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={onClose}
                onOpen={() => { }}
                PaperProps={{
                    sx: {
                        borderRadius: "12px 12px 0 0",
                        padding: "20px",
                        maxHeight: "60vh",
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        fontWeight: "bold",
                        gap: 1,
                    }}
                >
                    <img src={CheckIcon} alt="Confirmación" />
                    <Typography sx={{ fontSize: "20px" }} fontWeight="bold">{title}</Typography>
                </DialogTitle>

                <DialogContent>
                    <Typography variant="body2" textAlign="center">
                        {description.split(". ").map((sentence, index) => (
                            <React.Fragment key={index}>
                                {sentence}
                                {index !== description.split(". ").length - 1 && <br />}
                            </React.Fragment>
                        ))}
                    </Typography>
                </DialogContent>

                {/* Línea divisoria */}
                <Divider sx={{ mb: 1 }} />

                <DialogActions>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={onButtonClick || onClose}
                        sx={{ borderRadius: "12px", padding: "10px", fontWeight: "bold" }}
                    >
                        {buttonText}
                    </Button>
                </DialogActions>
            </SwipeableDrawer>
        );
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="xs"
            TransitionComponent={Slide}
            TransitionProps={{ direction: "down" }}
            sx={{
                "& .MuiDialog-paper": {
                    borderRadius: "16px",
                    padding: "16px",
                    textAlign: "center",
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    fontWeight: "bold",
                    gap: 1,
                }}
            >
                <img src={CheckIcon} alt="Confirmación" />
                <Typography sx={{ fontSize: "20px" }} fontWeight="bold">{title}</Typography>
            </DialogTitle>

            <DialogContent>
                <Typography variant="body2" textAlign="center">
                    {description.split(". ").map((sentence, index) => (
                        <React.Fragment key={index}>
                            {sentence}
                            {index !== description.split(". ").length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </Typography>
            </DialogContent>

            {/* Línea divisoria */}
            <Divider sx={{ mb: 1 }} />

            <DialogActions>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={onButtonClick || onClose}
                    sx={{ borderRadius: "12px", padding: "10px", fontWeight: "bold" }}
                >
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalSolicitudExitosa;
