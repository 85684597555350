import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ButtonsWrapper,
  PasoResumenComponent,
  ContentDatosSolicitud,
} from "./styles";
import * as SummarySection from "../../../../../../../components/SummarySection";
import JubilacionContext from "../../../../../../../context/jubilacion/pmo/jubPmoContext";
import { montoFormat } from "../../../../../../../helper/amountFormat";
import ICONFONDOLIBREBLUE from "../../../../../../../assets/IconBucket/ICON_FONDOLIBREBLUE";
import Alert from "../../../../../../../components/Alert";
import Button from "../../../../../../../components/Buttons";
const steps = [
  {
    status: "done",
    label: "Pensión Objetivo establecida",
    description: `Hemos recibido tu solicitud el ${new Date().toLocaleDateString(
      ["es-PE"],
      { day: "2-digit", month: "2-digit", year: "numeric" }
    )}.`,
  },
  {
    status: "waiting",
    label: "Solicita tu Jubilación Legal",
    description:
      "Al jubilarte a los 65 años, podrás acceder a tu Pensión Objetivo si has alcanzado o superado los fondos necesarios. En caso de haber un excedente, podrás retirarlo.",
  },
];
const PasoResumen = () => {
  const jubilacionContext = useContext(JubilacionContext);
  const { dataAcogimiento, datoEliminarAcogimiento } = jubilacionContext;
  const [arraySolicitud, setArraySolicitud] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDelete, setShowAlertDelete] = useState(false);

  const [showStepper, setShowStepper] = useState(false);
  useEffect(() => {
    if (dataAcogimiento) {
      if (dataAcogimiento.r_validacion === 1) {
        setShowStepper(true);
        let objSolicitud = [
          {
            label: "N.° de solicitud",
            value: dataAcogimiento.r_solicitud,
          },
          {
            label: "Fecha de solicitud",
            value: dataAcogimiento.r_fecha,
          },
          {
            label: "Monto de pensión mensual deseada",
            value: `S/ ${montoFormat(dataAcogimiento.r_m_pension)}`,
          },
          {
            label: "Fondos necesarios",
            value: `S/ ${montoFormat(dataAcogimiento.r_f_necesarios)}`,
          },
          {
            label: "Productos considerados",
            value: dataAcogimiento.r_productos,
          },
        ];
        setArraySolicitud(objSolicitud);
        let showFondoLibre = dataAcogimiento.r_f_libre === 1 ? true : false;
        setShowAlert(showFondoLibre);
      }
    }
  }, [dataAcogimiento]);

  useEffect(() => {
    if (datoEliminarAcogimiento) {
      if (datoEliminarAcogimiento.r_validacion === 1) {
        let objSolicitud = [
          {
            label: "N.° de solicitud",
            value: datoEliminarAcogimiento.id,
          },
          {
            label: "Fecha de solicitud",
            value: datoEliminarAcogimiento.fecha,
          },
        ];
        setArraySolicitud(objSolicitud);
        setShowAlertDelete(true);
      }
    } else {
    }
  }, [datoEliminarAcogimiento]);
  const history = useHistory();
  const onGoBackToHabitat = () =>
    history.push("/mi-habitat-digital/afiliado/inicio");
  const goToInvertir = () =>
    history.push("/mi-habitat-digital/afiliado/aporte-voluntario-sin-fin");

  return (
    <PasoResumenComponent>
      <SummarySection.Section>
        <SummarySection.Header style={{ paddingTop: "8px" }}>
          {showAlertDelete
            ? "Pensión Objetivo eliminada correctamente"
            : "Pensión Objetivo establecida correctamente"}
        </SummarySection.Header>

        {showAlert && (
          <Alert
            title={"Fondo Libre Habitat"}
            icon={<ICONFONDOLIBREBLUE />}
            description={
              <span>
                Alcanza más rápido tu pensión objetivo invirtiendo en el Fondo
                Libre Habitat
              </span>
            }
            action={"Invertir"}
            onAction={goToInvertir}
          />
        )}

        {showAlertDelete && (
          <Alert
            description={
              <span>
                Podrás volver a establecer tu Pensión Objetivo en cualquier
                momento desde{" "}
                <b> {"Inicio > Jubilación > Otras opciones de Jubilación > Establecer Pensión Objetivo."}</b>
              </span>
            }
          />
        )}

        <ContentDatosSolicitud stepperShow={showStepper}>
          <SummarySection.Table>
            <SummarySection.TableTitle>
              Datos de la solicitud
            </SummarySection.TableTitle>
            <SummarySection.TableSection>
              {arraySolicitud.map(({ label, value, show }, i) => (
                <SummarySection.TableItem
                  key={label}
                  label={label}
                  value={value}
                />
              ))}
            </SummarySection.TableSection>
          </SummarySection.Table>
          {showStepper && (
            <SummarySection.Progress>
              <SummarySection.ProgressHeader>
                Avance de solicitud
              </SummarySection.ProgressHeader>
              <SummarySection.ProgressBody
                steps={steps}
              ></SummarySection.ProgressBody>
            </SummarySection.Progress>
          )}
        </ContentDatosSolicitud>
      </SummarySection.Section>
      <ButtonsWrapper>
        <Button fullWidth size="lg" onClick={onGoBackToHabitat}>
          Volver al inicio
        </Button>
      </ButtonsWrapper>
    </PasoResumenComponent>
  );
};

export default PasoResumen;
