import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import hocWindowWidth from "../../HOC/HocWidthWindow";
import { MobileMenu, DesktopMenu } from "./components";
import { useSwipeable } from "react-swipeable";
// import { optionMenuArray } from "../../utils/constants";
import Canbiodefondo from "../../context/cambiofondo/cambiofondoContext";
const MainDrawer = (props) => {
  const cambiofindoContext = useContext(Canbiodefondo);
  const { connectEtario } = cambiofindoContext;
  const location = useLocation();
  const { isOpen, setIsOpen, optionMenuArray } = props;

  const [isMobile, setIsMobile] = useState(true);

  const [isDrawerCollapse, setIsDrawerCollapse] = useState(true);
  const [optionSelected, setOptionSelected] = useState("");
  const handlers = useSwipeable({
    delta: 0,
    trackMouse: false,
    preventScrollOnSwipe: true,
    trackTouch: true,
    onSwipedRight: (e) => {
      setIsOpen(true);
    },
  });

  const filterSession = location.pathname.split("/");

  const selectOption =
    filterSession[3] === "apv" || filterSession[3] === "seguimiento"
      ? "apvf"
      : filterSession[3] === "inicio"
        ? "home"
        : filterSession[3] === "jubilacion"
          ? "benefits"
          : filterSession[3] === "mis-fondos"
            ? "myFund"
            : "";

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      // document.body.style.overflow = "auto";
      setOptionSelected(selectOption);
      setIsDrawerCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  useEffect(() => {
    if (props.windowWidth > 980 && isMobile === true) {
      setIsMobile(false);
      if (isOpen) setIsOpen(false);
    } else if (props.windowWidth <= 980 && isMobile === false)
      setIsMobile(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.windowWidth]);

  if (isMobile)
    return (
      <MobileMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isDrawerCollapse={isDrawerCollapse}
        optionMenuArray={optionMenuArray}
        optionSelected={optionSelected}
        setOptionSelected={setOptionSelected}
        setIsDrawerCollapse={setIsDrawerCollapse}
        history={props.history}
        handlers={handlers}
        location={location}
        connectEtario={connectEtario}
      />
    );
  else
    return (
      <DesktopMenu
        optionMenuArray={optionMenuArray}
        optionSelected={optionSelected}
        setOptionSelected={setOptionSelected}
        history={props.history}
        location={location}
        connectEtario={connectEtario}
      />
    );
};

export default hocWindowWidth(MainDrawer);
