import React, { useState, useEffect, useContext } from "react";
import JubilacionContext from "../../../../context/jubilacion/jubilacionContext";
import ModalContext from "../../../../context/modal/modalContext";
import { Progress } from "reactstrap";
import { Paso1, Paso2, Paso3, Paso5, Paso4, Paso6, Paso7 } from "./etapas";
import { MenuStep } from "./etapas/components";
import Grid from "@mui/material/Grid";
import Loading from "../../../../components/Loading/index.jsx";
import "./index.css";

export default function JubilacionRejaWorkflow(props) {
  const [currectStepNumber, setCurrectStepNumber] = useState(0);
  const [currectStepTitle, setCurrectStepTitle] = useState("");
  const [processId, setProcessId] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [stepsArray, setStepsArray] = useState([]);

  const jubilacionContext = useContext(JubilacionContext);
  const {
    rejaData,
    rejaDataLoading,
    obtenerProcesoRejaData,
    limpiarObtenerProcesoRejaData,
    mensajeAlerta,
  } = jubilacionContext;
  // console.log("WORFLOW: ", rejaData);
  // console.log(
  //   "VALOR: ",
  //   rejaData?.data?.type_account_bank
  //     ? rejaData.data.type_account_bank === 1
  //       ? true
  //       : false
  //     : "NO HAY"
  // );
  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;
  const mostrarMensaje = (mensaje) => {
    setMessage(mensaje);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    obtenerProcesoRejaData();
    return () => {
      limpiarObtenerProcesoRejaData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mensajeAlerta) mostrarMensaje(mensajeAlerta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mensajeAlerta]);

  useEffect(() => {
    if (rejaData) {
      if (rejaData.r_validacion === 1) {
        if (
          rejaData.data.status === "in-process" ||
          rejaData.data.status === "pending"
        ) {
          setCurrectStepNumber(rejaData.data.step);
          setProcessId(rejaData.data.procedure_id);
          setStepsArray(rejaData.data.timeline);
        } else props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion");
      } else {
        props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejaData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currectStepNumber]);

  const getOutOfProcess = () => {
    props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion");
  };
  const handleShowMenu = () => {
    setShowMenu(true);
  };
  const renderStepComponent = () => {
    if (currectStepNumber === 1)
      return (
        <Paso1
          processId={processId}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
        />
      );
    else if (currectStepNumber === 2)
      return (
        <Paso2
          processId={processId}
          getOutOfProcess={getOutOfProcess}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
        />
      );
    else if (currectStepNumber === 3)
      return (
        <Paso3
          processId={processId}
          getOutOfProcess={getOutOfProcess}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
        />
      );
    else if (currectStepNumber === 4)
      return (
        <Paso4
          processId={processId}
          getOutOfProcess={getOutOfProcess}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
        />
      );
    else if (currectStepNumber === 5)
      return (
        <Paso5
          processId={processId}
          getOutOfProcess={getOutOfProcess}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
        />
      );
    else if (currectStepNumber === 6)
      return (
        <Paso6
          processId={processId}
          getOutOfProcess={getOutOfProcess}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
        />
      );
    else if (currectStepNumber === 7)
      return (
        <Paso7
          processId={processId}
          getOutOfProcess={getOutOfProcess}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
          history={props.history}
        />
      );
    else return <p></p>;
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (rejaDataLoading) return <Loading />;

  return (
    <Grid container p={2} justifyContent="center">
      <div className="reja-container">
        <div className="btn-step mb-3">
          <button id="btn-menu-reja" onClick={() => handleShowMenu()}>
            Ver pasos
          </button>
        </div>
        <div className="reja-header header-step">
          <Progress
            className="reja-progress-bar"
            color="success"
            value={currectStepNumber}
            max={7}
          />

          <h4>{currectStepTitle}</h4>
        </div>
        <div className="reja-body">{renderStepComponent()}</div>
        <MenuStep
          currectStepNumber={currectStepNumber}
          show={showMenu}
          stepArray={stepsArray}
          setShow={setShowMenu}
        />
      </div>
    </Grid>
  );
}
