import React from "react";
import { Box, Grid, Typography, Button, Paper } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.css";

// Diccionario de descripciones
const descripcionesJubilacion = {
    reja: {
        title: "Jubilación Anticipada por Desempleo",
        description:
            "Anticípate a tu jubilación si tienes 50 años o más y estás desempleado de forma continua durante al menos 12 meses, recuerda que puedes optar estas opciones de Retiro y/o Pensión:",
        options: [
            "Pensión mensual",
            "Retiro de hasta 95.5% de tu fondo",
        ],
    },
    legal: {
        title: "Jubilación por Edad Legal",
        description:
            "A partir de los 65 años, puedes acceder a la jubilación, recuerda que puedes optar por estas opciones de Retiro y/o Pensión:",
        options: [
            "Pensión mensual",
            "Retiro de hasta 95.5 % de tu fondo",
            "Pensión Objetivo",
        ],
    },
    nuevoRetiro: {
        title: "Nuevo Retiro de hasta 95.5%",
        description: "Realiza un nuevo retiro de tu Fondo de Jubilación.",
    },
};

const JubilacionCard = ({ tipoJub, icon, buttonLabel, onClick, disabled, showSpinner }) => {
    const { title, description, options } = descripcionesJubilacion[tipoJub] || {};

    if (!title || !description) {
        return (
            <Typography color="error">
                Tipo de jubilación no válido.
            </Typography>
        );
    }

    return (
        <Paper elevation={3} className="jubilacion-card">
            <Grid container spacing={2}>
                {/* Icono y Título */}
                <Grid item xs={12} className="header-container">
                    <Box className="icon-title">
                        <img src={icon} alt={title} className="icon-image" />
                        <Typography variant="h6" fontWeight="bold" className="card-title">
                            {title}
                        </Typography>
                    </Box>
                </Grid>

                {/* Descripción y Opciones */}
                <Grid item xs={12} className="description-container">
                    <Typography variant="body1" className="card-description">
                        {description}
                    </Typography>

                    {/* Opciones (si existen) */}
                    {options && (
                        <Box className="options-container">
                            {options.map((option, index) => (
                                <Typography key={index} variant="body2" className="option-text">
                                    • {option}
                                </Typography>
                            ))}
                        </Box>
                    )}
                </Grid>

                {/* Botón */}
                <Grid item xs={12} className="button-container">
                    <Button
                        variant="contained"
                        onClick={onClick}
                        disabled={disabled}
                        endIcon={<ArrowForwardIosIcon />}
                    >
                        {buttonLabel}
                        {/* Mostrar Spinner si `showSpinner` es true */}
                        {showSpinner && (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        )}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default JubilacionCard;
