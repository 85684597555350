import React, { useReducer } from "react";
import JubilacionContext from "./jubilacionContext";
import JubilacionReducer from "./jubilacionReducer";
import clienteAxios from "../../config/axios";
import {
  JUBILACION_POST_EXITOSO,
  JUBILACION_POST_ERROR,
  JUBILACION_POST_LOADIN,
  JUBILACION_CLEANER,
  JUBILACION_CLEANER_PARCIAL,
  MENU_DATA,
  ACTUALIZAR_PROCESO_JUBILACION_CLEANER,
  ACTUALIZAR_PROCESO_JUBILACION_ERROR,
  ACTUALIZAR_PROCESO_JUBILACION_EXITOSO,
  ACTUALIZAR_PROCESO_JUBILACION_LOADIN,
  CREAR_PROCESO_JUBILACION_CLEANER,
  CREAR_PROCESO_JUBILACION_ERROR,
  CREAR_PROCESO_JUBILACION_EXITOSO,
  CREAR_PROCESO_JUBILACION_LOADIN,
  OBTENER_PROCESO_JUBILACION_CLEANER,
  OBTENER_PROCESO_JUBILACION_ERROR,
  OBTENER_PROCESO_JUBILACION_EXITOSO,
  OBTENER_PROCESO_JUBILACION_LOADIN,
  OBTENER_PROCESO_LEGAL_LOADIN,
  OBTENER_PROCESO_LEGAL_EXITOSO,
  OBTENER_PROCESO_LEGAL_ERROR,
  OBTENER_PROCESO_LEGAL_CLEANER,
  CREAR_PROCESO_LEGAL_LOADIN,
  CREAR_PROCESO_LEGAL_EXITOSO,
  CREAR_PROCESO_LEGAL_ERROR,
  CREAR_PROCESO_LEGAL_CLEANER,
  OBTENER_CONTENIDO_PASO_JUBILACION_LOADIN,
  OBTENER_CONTENIDO_PASO_JUBILACION_EXITOSO,
  OBTENER_CONTENIDO_PASO_JUBILACION_ERROR,
  OBTENER_CONTENIDO_PASO_JUBILACION_CLEANER,
  GUARDAR_AVANCE_TRAMITE_JUBILACION_LOADIN,
  GUARDAR_AVANCE_TRAMITE_JUBILACION_EXITOSO,
  GUARDAR_AVANCE_TRAMITE_JUBILACION_ERROR,
  REJA_SGA_VALIDAR_PENSION_LOADING,
  REJA_SGA_VALIDAR_PENSION_EXITOSO,
  REJA_SGA_VALIDAR_PENSION_ERROR,
  REJA_SGA_VALIDAR_PENSION_CLEANER,
  OBTENER_PROCESO_REJA_LOADIN,
  OBTENER_PROCESO_REJA_EXITOSO,
  OBTENER_PROCESO_REJA_ERROR,
  OBTENER_PROCESO_REJA_CLEANER,
  CREAR_PROCESO_REJA_LOADIN,
  CREAR_PROCESO_REJA_EXITOSO,
  CREAR_PROCESO_REJA_ERROR,
  CREAR_PROCESO_REJA_CLEANER,
  GUARDAR_PASO_DOS_REJA_LOADIN,
  GUARDAR_PASO_DOS_REJA_EXITOSO,
  GUARDAR_PASO_DOS_REJA_ERROR,
  GUARDAR_PASO_DOS_REJA_CLEANER,
  OBTENER_PARENTESCOS_REJA_LOADIN,
  OBTENER_PARENTESCOS_REJA_EXITOSO,
  OBTENER_PARENTESCOS_REJA_ERROR,
  OBTENER_PARENTESCOS_REJA_CLEANER,
  REJA_ESTIMACION_LOADING,
  REJA_ESTIMACION_EXITOSO,
  REJA_ESTIMACION_ERROR,
  REJA_ESTIMACION_CLEANER,
  REJA_PDF_ESTIMACION_LOADING,
  REJA_PDF_ESTIMACION_EXITOSO,
  REJA_PDF_ESTIMACION_ERROR,
  REJA_PDF_ESTIMACION_CLEANER,
  CREAR_PROCESO_ETAPA1_LOADIN,
  CREAR_PROCESO_ETAPA1_CLEANER,
  CREAR_PROCESO_ETAPA1_ERROR,
  CREAR_PROCESO_ETAPA1_EXITOSO,
  OBTENER_TEMAS_ASESORIA_REJA_LOADIN,
  OBTENER_TEMAS_ASESORIA_REJA_EXITOSO,
  OBTENER_TEMAS_ASESORIA_REJA_ERROR,
  OBTENER_TEMAS_ASESORIA_REJA_CLEANER,
  CREAR_PROCESO_ETAPA2_LOADIN,
  CREAR_PROCESO_ETAPA2_CLEANER,
  CREAR_PROCESO_ETAPA2_ERROR,
  CREAR_PROCESO_ETAPA2_EXITOSO,
  CREAR_PROCESO_ETAPA3_LOADIN,
  CREAR_PROCESO_ETAPA3_EXITOSO,
  CREAR_PROCESO_ETAPA3_ERROR,
  CREAR_PROCESO_ETAPA3_CLEANER,
  OBTENER_MARCO_BONO_LOADIN,
  OBTENER_MARCO_BONO_EXITOSO,
  OBTENER_MARCO_BONO_ERROR,
  OBTENER_MARCO_BONO_CLEANER,
  CREAR_PROCESO_ETAPA4_LOADIN,
  CREAR_PROCESO_ETAPA4_EXITOSO,
  CREAR_PROCESO_ETAPA4_ERROR,
  CREAR_PROCESO_ETAPA4_CLEANER,
  CREAR_PROCESO_ETAPA5_LOADIN,
  CREAR_PROCESO_ETAPA5_EXITOSO,
  CREAR_PROCESO_ETAPA5_ERROR,
  CREAR_PROCESO_ETAPA5_CLEANER,
  OBTENER_MARCO_PENSION_LOADIN,
  OBTENER_MARCO_PENSION_EXITOSO,
  OBTENER_MARCO_PENSION_ERROR,
  OBTENER_MARCO_PENSION_CLEANER,
  CREAR_PROCESO_ETAPA6_LOADIN,
  CREAR_PROCESO_ETAPA6_EXITOSO,
  CREAR_PROCESO_ETAPA6_ERROR,
  CREAR_PROCESO_ETAPA6_CLEANER,
  CREAR_PROCESO_ETAPA7_LOADIN,
  CREAR_PROCESO_ETAPA7_EXITOSO,
  CREAR_PROCESO_ETAPA7_ERROR,
  CREAR_PROCESO_ETAPA7_CLEANER,
  OBTENER_PASO_6_REJA_DATOS,
  RETIRO_RESETEAR,
  ACCEDER_JAO_LOADING,
  ACCEDER_JAO_EXITOSO,
  ACCEDER_JAO_ERROR,
  ACCEDER_JAO_CLEAR,
  GET_JAO_PROCESS_LOADING,
  GET_JAO_PROCESS_EXITOSO,
  GET_JAO_PROCESS_ERROR,
  GET_JAO_PROCESS_CLEAR,
  GET_JAO_BENEFICIARIES_LOADING,
  GET_JAO_BENEFICIARIES_EXITOSO,
  GET_JAO_BENEFICIARIES_ERROR,
  GET_JAO_BENEFICIARIES_CLEAR,
  SAVE_JAO_BENEFICIARIES_LOADING,
  SAVE_JAO_BENEFICIARIES_EXITOSO,
  SAVE_JAO_BENEFICIARIES_ERROR,
  SAVE_JAO_BENEFICIARIES_CLEAR,
  GET_JAO_SALDOS_LOADING,
  GET_JAO_SALDOS_EXITOSO,
  GET_JAO_SALDOS_ERROR,
  GET_JAO_SALDOS_CLEAR,
  SAVE_JAO_SALDOS_LOADING,
  SAVE_JAO_SALDOS_EXITOSO,
  SAVE_JAO_SALDOS_ERROR,
  SAVE_JAO_SALDO_CLEAR,
  SAVE_JAO_AVISO_LOADING,
  SAVE_JAO_AVISO_EXITOSO,
  SAVE_JAO_AVISO_ERROR,
  SAVE_JAO_AVISO_CLEAR,
  GET_JAO_RESULTADO_LOADING,
  GET_JAO_RESULTADO_EXITOSO,
  GET_JAO_RESULTADO_ERROR,
  GET_JAO_RESULTADO_CLEAR,
  GET_ACCEDE_SEGUNDO_RETIRO_LOADING,
  GET_ACCEDE_SEGUNDO_RETIRO_EXITOSO,
  GET_ACCEDE_SEGUNDO_RETIRO_ERROR,
  GET_ACCEDE_SEGUNDO_RETIRO_CLEAR,
  SEGUNDO_RETIRO_TYPE_BANK,
  JUBILACION_LEGAL_TYPE_BANK,
  REJA_TYPE_BANK,
} from "../../types/jubilacion";

const MisDatosState = (props) => {
  const initialState = {
    /**Actualizar Datos*/
    resultadoJubilacionPost: null,
    mensajeAlertaJubilacionPost: null,
    loadingBtnJubilacionPost: false, // procesando la actualización de datos
    /**Actualizar Datos*/
    opcionesMenu: null,
    procedureActives: null,
    /*Jubilacion Legal Asesoria*/
    jubilacionData: null,
    jubilacionDataLoading: false,
    createJubilacionData: null,
    createJubilacionDataLoading: false,
    updateProcessData: null,
    updateProcessLoading: false,
    /*Jubilacion Legal */
    legalDataLoading: false,
    legalData: null,
    createLegalDataLoading: false,
    createLegalData: null,
    contenidoTemaJubilacionLoading: false,
    contenidoTemaJubilacionData: null,
    guardarAvanceJuilacionLoading: false,
    guardarAvanceJuilacionData: null,
    /*Jubilacion Reja */
    rejaSgaValidarData: null,
    rejaSgaValidarLoading: false,
    rejaData: null,
    rejaDataLoading: false,
    createRejaData: null,
    createRejaDataLoading: false,
    /*Paso 1 */
    guardarPaso1ResponseLoading: false,
    guardarPaso1Response: null,
    temasAsesoriaRejaLoading: false,
    temasAsesoriaRejaData: null,
    /*Paso 2*/
    relationshipsData: null,
    relationshipsLoading: false,
    saveStepTwoData: null,
    saveStepTwoDataLoading: false,

    guardarPaso2ResponseLoading: false,
    guardarPaso2Response: null,
    /*Paso 3 */
    guardarPaso3ResponseLoading: false,
    guardarPaso3Response: null,
    marcoBonoDataLoading: false,
    marcoBonoData: null,
    /*Paso 4 */
    estimacionRejaLoading: false,
    estimacionRejaData: null,
    estimacionPDFRejaLoading: false,
    estimacionPDFRejaData: null,
    guardarPaso4ResponseLoading: false,
    guardarPaso4Response: null,
    /*Paso5 */
    guardarPaso5Response: null,
    guardarPaso5ResponseLoading: false,
    marcoPensionDataLoading: false,
    marcoPensionData: null,
    /*Paso6 */
    guardarPaso6Response: null,
    guardarPaso6ResponseLoading: false,
    guardarModalidad: null,
    guardarAPVf: null,
    /*Paso7 */
    guardarPaso7Response: null,
    guardarPaso7ResponseLoading: false,
    /*JAO */
    jaoAccessData: null,
    jaoAccessLoading: false,
    jaoProcessData: null,
    jaoProcessLoading: false,
    jaoBeneficiariesLoading: false,
    jaoBeneficiariesData: null,
    saveJaoBeneficiariesLoading: false,
    saveJaoBeneficiariesData: null,
    jaoSaldosLoading: false,
    jaoSaldosData: null,
    saveJaoSaldosLoading: false,
    saveJaoSaldosData: null,
    saveJaoAvisoLoading: false,
    saveJaoAvisoData: null,
    jaoResultadoLoading: false,
    jaoResultadoData: null,
    /*SEGUNDO RETIRO */
    accedeSegundoRetiroDataLoading: false,
    accedeSegundoRetiroData: null,

    mensajeAlerta: null,
    typeBankSR: null,
    typeBankJL: null,
    typeBankJR: null,
  };

  const handleErrorsLocal = (error, origin_error) => {
    if (error.message === "Network Error") {
      dispatch({
        type: origin_error,
        payload: {
          code: 1, // error de red
          msg: "Su red presenta lentitud, por favor recargue la página.",
        },
      });
    } else {
      if (error.response) {
        if (
          error.response.status === 500 ||
          error.response.status === 400 ||
          error.response.status === 404
        ) {
          dispatch({
            type: origin_error,
            payload: {
              code: 1, // su token a expirado
              msg: "Error en los servicios, por favor recargue la página.",
            },
          });
        } else if (
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.message === "Unauthenticated."
        ) {
          dispatch({
            type: origin_error,
            payload: {
              code: 2, // su token a expirado
              msg: "Su sesión ha expirado, por favor vuelva a iniciar sesión.",
            },
          });
        } else {
          dispatch({
            type: origin_error,
            payload: {
              code: 1, // su token a expirado
              msg: error.response.data.message,
            },
          });
        }
      }
    }
  };

  const obtenerAccesoJaoData = async () => {
    dispatch({
      type: ACCEDER_JAO_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(`/jao/access`);
      dispatch({
        type: ACCEDER_JAO_EXITOSO,
        payload: respuesta.data,
        // payload: {
        //   "success": true,
        //   "message": "Afiliado accede a JAO",
        //   "data": {
        //     "access_reja": true,
        //   }
        // },
      });
    } catch (error) {
      handleErrorsLocal(error, ACCEDER_JAO_ERROR);
    }
  };

  const limpiarObtenerAccesoJaoData = () => {
    dispatch({
      type: ACCEDER_JAO_CLEAR,
    });
  };

  const obtenerProcesoJaoData = async () => {
    dispatch({
      type: GET_JAO_PROCESS_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(`/jao/view`);
      dispatch({
        type: GET_JAO_PROCESS_EXITOSO,
        payload: respuesta.data,
        // payload: {
        //   "success": true,
        //   "data": {
        //     "pay_history": true,
        //     "funds": {
        //       "obligatory": {
        //         "format": "S/.100,000.00",
        //         "number": 100000
        //       },
        //       "voluntary": {
        //         "format": "S/.100.00",
        //         "number": 100
        //       },
        //       "free": {
        //         "format": "S/.50,000",
        //         "number": 50000
        //       },
        //     },
        //     "date": "Viernes 27 de agosto",
        //     "has_april_remuneration": true
        //   }
        // },
        // payload: {
        //   "success": true,
        //   "data": {
        //     "pay_history": false,
        //   }
        // }
      });
    } catch (error) {
      handleErrorsLocal(error, GET_JAO_PROCESS_ERROR);
    }
  };
  const limpiarObtenerProcesoJaoData = () => {
    dispatch({
      type: GET_JAO_PROCESS_CLEAR,
    });
  };

  const obtenerBeneficiariosJaoData = async () => {
    dispatch({
      type: GET_JAO_BENEFICIARIES_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(`/`);
      dispatch({
        type: GET_JAO_BENEFICIARIES_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, GET_JAO_BENEFICIARIES_ERROR);
    }
  };
  const limpiarObtenerBeneficiariosJaoData = () => {
    dispatch({
      type: GET_JAO_BENEFICIARIES_CLEAR,
    });
  };

  const guardarBeneficiariosJaoData = async () => {
    dispatch({
      type: SAVE_JAO_BENEFICIARIES_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(`/`);
      dispatch({
        type: SAVE_JAO_BENEFICIARIES_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, SAVE_JAO_BENEFICIARIES_ERROR);
    }
  };
  const limpiarGuardarBeneficiariosJaoData = () => {
    dispatch({
      type: SAVE_JAO_BENEFICIARIES_CLEAR,
    });
  };

  const obtenerSaldosJaoData = async () => {
    dispatch({
      type: GET_JAO_SALDOS_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(`/`);
      dispatch({
        type: GET_JAO_SALDOS_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, GET_JAO_SALDOS_ERROR);
    }
  };
  const limpiarObtenerSaldosJaoData = () => {
    dispatch({
      type: GET_JAO_SALDOS_CLEAR,
    });
  };

  const guardarSaldosJaoData = async () => {
    dispatch({
      type: SAVE_JAO_SALDOS_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(`/`);
      dispatch({
        type: SAVE_JAO_SALDOS_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, SAVE_JAO_SALDOS_ERROR);
    }
  };
  const limpiarGuardarSaldosJaoData = () => {
    dispatch({
      type: SAVE_JAO_SALDO_CLEAR,
    });
  };

  const guardarAvisoJaoData = async (datos) => {
    dispatch({
      type: SAVE_JAO_AVISO_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(`/jao/evaluation`, datos);
      dispatch({
        type: SAVE_JAO_AVISO_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, SAVE_JAO_AVISO_ERROR);
    }
  };
  const limpiarGuardarAvisoJaoData = () => {
    dispatch({
      type: SAVE_JAO_AVISO_CLEAR,
    });
  };

  const obtenerRespuestaEvaluacionJaoData = async () => {
    dispatch({
      type: GET_JAO_RESULTADO_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(`/`);
      dispatch({
        type: GET_JAO_RESULTADO_EXITOSO,
        payload: respuesta.data,
        //payload: ramdonResponse[Math.floor(Math.random() * ramdonResponse.length)],
        //payload: ramdonResponse[1]
      });
    } catch (error) {
      handleErrorsLocal(error, GET_JAO_RESULTADO_ERROR);
    }
  };
  const limpiarObtenerRespuestaEvaluacionJaoData = () => {
    dispatch({
      type: GET_JAO_RESULTADO_CLEAR,
    });
  };

  const obtenerProcesoSegundoRetiroData = async () => {
    dispatch({
      type: GET_ACCEDE_SEGUNDO_RETIRO_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(`/segunda-decision/access`);
      dispatch({
        type: GET_ACCEDE_SEGUNDO_RETIRO_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, GET_ACCEDE_SEGUNDO_RETIRO_ERROR);
    }
  };
  const limpiarObtenerProcesoSegundoRetiroData = () => {
    dispatch({
      type: GET_ACCEDE_SEGUNDO_RETIRO_CLEAR,
    });
  };

  const obtenerProcesoLegalData = async () => {
    dispatch({
      type: OBTENER_PROCESO_LEGAL_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(`/legal/access`);
      dispatch({
        type: OBTENER_PROCESO_LEGAL_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, OBTENER_PROCESO_LEGAL_ERROR);
    }
  };
  const limpiarObtenerProcesoLegalData = () => {
    dispatch({
      type: OBTENER_PROCESO_LEGAL_CLEANER,
    });
  };

  const crearProcesoLegalData = async (datos) => {
    dispatch({
      type: CREAR_PROCESO_LEGAL_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post("/procedures", datos);
      dispatch({
        type: CREAR_PROCESO_LEGAL_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CREAR_PROCESO_LEGAL_ERROR);
    }
  };
  const limpiarCrearProcesoLegalData = () => {
    dispatch({
      type: CREAR_PROCESO_LEGAL_CLEANER,
    });
  };

  const obtenerContenidoTemaJubilacion = async (procedure_id, step) => {
    dispatch({
      type: OBTENER_CONTENIDO_PASO_JUBILACION_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(
        `/procedures/v1/${procedure_id}/step/${step}`
      );
      dispatch({
        type: OBTENER_CONTENIDO_PASO_JUBILACION_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, OBTENER_CONTENIDO_PASO_JUBILACION_ERROR);
    }
  };
  const limpiarObtenerContenidoTemaJubilacion = () => {
    dispatch({
      type: OBTENER_CONTENIDO_PASO_JUBILACION_CLEANER,
    });
  };

  const guardarAvanceTramiteJubilacion = async (
    procedure_id,
    step,
    datos = null
  ) => {
    dispatch({
      type: GUARDAR_AVANCE_TRAMITE_JUBILACION_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        `/procedures/v1/${procedure_id}/step/${step}`,
        datos
      );
      dispatch({
        type: GUARDAR_AVANCE_TRAMITE_JUBILACION_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, GUARDAR_AVANCE_TRAMITE_JUBILACION_ERROR);
    }
  };
  const limpiarGuardarAvanceTramiteJubilacion = () => {
    dispatch({
      type: OBTENER_CONTENIDO_PASO_JUBILACION_CLEANER,
    });
  };

  const obtenerProcesoRejaData = async () => {
    dispatch({
      type: OBTENER_PROCESO_REJA_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(`/procedures/access-reja`);
      dispatch({
        type: OBTENER_PROCESO_REJA_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, OBTENER_PROCESO_REJA_ERROR);
    }
  };
  const limpiarObtenerProcesoRejaData = () => {
    dispatch({
      type: OBTENER_PROCESO_REJA_CLEANER,
    });
  };

  const enviarRejaSgaValidador = async () => {
    dispatch({
      type: REJA_SGA_VALIDAR_PENSION_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        `/procedures/store-first-attention`
      );
      dispatch({
        type: REJA_SGA_VALIDAR_PENSION_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, REJA_SGA_VALIDAR_PENSION_ERROR);
    }
  };
  const limpiarEnviarRejaSgaValidador = () => {
    dispatch({
      type: REJA_SGA_VALIDAR_PENSION_CLEANER,
    });
  };

  const crearProcesoRejaData = async (datos) => {
    dispatch({
      type: CREAR_PROCESO_REJA_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post("/procedures", datos);
      dispatch({
        type: CREAR_PROCESO_REJA_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CREAR_PROCESO_REJA_ERROR);
    }
  };
  const limpiarCrearProcesoRejaData = () => {
    dispatch({
      type: CREAR_PROCESO_REJA_CLEANER,
    });
  };

  // PRIMER PASO
  const crearProcesoRejaEtapa1 = async (procedure_id, datos) => {
    dispatch({
      type: CREAR_PROCESO_ETAPA1_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    const data = {
      content_id: datos,
    };
    try {
      const respuesta = await clienteAxios.post(
        `/procedures/${procedure_id}/store-advice`,
        data
      );
      dispatch({
        type: CREAR_PROCESO_ETAPA1_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CREAR_PROCESO_ETAPA1_ERROR);
    }
  };
  const limpiarCrearProcesoRejaEtapa1 = () => {
    dispatch({
      type: CREAR_PROCESO_ETAPA1_CLEANER,
    });
  };

  const obtenerContenidoPasoReja = async (procedure_id, step) => {
    dispatch({
      type: OBTENER_TEMAS_ASESORIA_REJA_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(
        `/procedures/${procedure_id}/step/${step}`
      );
      dispatch({
        type: OBTENER_TEMAS_ASESORIA_REJA_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, OBTENER_TEMAS_ASESORIA_REJA_ERROR);
    }
  };
  const limpiarObtenerContenidoPasoReja = () => {
    dispatch({
      type: OBTENER_TEMAS_ASESORIA_REJA_CLEANER,
    });
  };

  // SEGUNDO PASO
  const crearProcesoRejaEtapa2 = async (procedureId, datos) => {
    dispatch({
      type: CREAR_PROCESO_ETAPA2_LOADIN,
      payload: true, // mandamos el loading al boton
    });

    try {
      const respuesta = await clienteAxios.post(
        `procedures/${procedureId}/store-second-stage`,
        datos
      );
      dispatch({
        type: CREAR_PROCESO_ETAPA2_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CREAR_PROCESO_ETAPA2_ERROR);
    }
  };
  const limpiarCrearProcesoRejaEtapa2 = () => {
    dispatch({
      type: CREAR_PROCESO_ETAPA2_CLEANER,
    });
  };

  // TERCER PASO 3
  const crearProcesoRejaEtapa3 = async (procedureId, datos) => {
    dispatch({
      type: CREAR_PROCESO_ETAPA3_LOADIN,
      payload: true, // mandamos el loading al boton
    });

    try {
      const respuesta = await clienteAxios.post(
        `/procedures/${procedureId}/store-third-stage`,
        datos
      );
      dispatch({
        type: CREAR_PROCESO_ETAPA3_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CREAR_PROCESO_ETAPA3_ERROR);
    }
  };
  const limpiarCrearProcesoRejaEtapa3 = () => {
    dispatch({
      type: CREAR_PROCESO_ETAPA3_CLEANER,
    });
  };

  const obtenerMarcoBonoReja = async (procedureId) => {
    dispatch({
      type: OBTENER_MARCO_BONO_LOADIN,
      payload: true, // mandamos el loading al boton
    });

    try {
      const respuesta = await clienteAxios.get(
        `/procedures/${procedureId}/check-bono`
      );
      dispatch({
        type: OBTENER_MARCO_BONO_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, OBTENER_MARCO_BONO_ERROR);
    }
  };
  const limpiarObtenerMarcoBonoReja = () => {
    dispatch({
      type: OBTENER_MARCO_BONO_CLEANER,
    });
  };

  // CUARTO PASO 4
  const crearProcesoRejaEtapa4 = async (procedureId, datos) => {
    dispatch({
      type: CREAR_PROCESO_ETAPA4_LOADIN,
      payload: true, // mandamos el loading al boton
    });

    try {
      const respuesta = await clienteAxios.post(
        `procedures/${procedureId}/store-fourth-stage`,
        datos
      );
      dispatch({
        type: CREAR_PROCESO_ETAPA4_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CREAR_PROCESO_ETAPA4_ERROR);
    }
  };
  const resetear = () => {
    dispatch({
      type: RETIRO_RESETEAR,
      payload: true, // mandamos el loading al boton
    });
  };
  const limpiarCrearProcesoRejaEtapa4 = () => {
    dispatch({
      type: CREAR_PROCESO_ETAPA4_CLEANER,
    });
  };
  // Quuinto PASO 5
  const crearProcesoRejaEtapa5 = async (procedureId, datos) => {
    dispatch({
      type: CREAR_PROCESO_ETAPA5_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        `procedures/${procedureId}/store-fifth-stage`,
        datos
      );
      dispatch({
        type: CREAR_PROCESO_ETAPA5_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CREAR_PROCESO_ETAPA5_ERROR);
    }
  };
  const limpiarCrearProcesoRejaEtapa5 = () => {
    dispatch({
      type: CREAR_PROCESO_ETAPA5_CLEANER,
    });
  };

  const obtenerMarcoPensionReja = async (procedureId) => {
    dispatch({
      type: OBTENER_MARCO_PENSION_LOADIN,
      payload: true, // mandamos el loading al boton
    });

    try {
      const respuesta = await clienteAxios.get(
        `/procedures/${procedureId}/check-pension`
      );
      dispatch({
        type: OBTENER_MARCO_PENSION_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, OBTENER_MARCO_PENSION_ERROR);
    }
  };
  const limpiarObtenerMarcoPensionReja = () => {
    dispatch({
      type: OBTENER_MARCO_PENSION_CLEANER,
    });
  };

  // Sexto PASO 6
  const crearProcesoRejaEtapa6 = async (procedureId, datos) => {
    dispatch({
      type: CREAR_PROCESO_ETAPA6_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        `procedures/${procedureId}/store-sixth-stage`,
        datos
      );
      dispatch({
        type: CREAR_PROCESO_ETAPA6_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CREAR_PROCESO_ETAPA6_ERROR);
    }
  };
  const guardarRejaEtapa6 = async (datos) => {
    dispatch({
      type: OBTENER_PASO_6_REJA_DATOS,
      payload: datos,
    });
  };
  const limpiarCrearProcesoRejaEtapa6 = () => {
    dispatch({
      type: CREAR_PROCESO_ETAPA6_CLEANER,
    });
  };

  // Sexto PASO 7
  const crearProcesoRejaEtapa7 = async (procedureId, datos) => {
    dispatch({
      type: CREAR_PROCESO_ETAPA7_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        `procedures/${procedureId}/store-seven-stage`,
        datos
      );
      dispatch({
        type: CREAR_PROCESO_ETAPA7_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CREAR_PROCESO_ETAPA7_ERROR);
    }
  };
  const limpiarCrearProcesoRejaEtapa7 = () => {
    dispatch({
      type: CREAR_PROCESO_ETAPA7_CLEANER,
    });
  };

  const obtenerParentescosRejaData = async () => {
    dispatch({
      type: OBTENER_PARENTESCOS_REJA_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(`/procedures/relationships`);
      dispatch({
        type: OBTENER_PARENTESCOS_REJA_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, OBTENER_PARENTESCOS_REJA_ERROR);
    }
  };
  const limpiarObtenerParentescosRejaData = () => {
    dispatch({
      type: OBTENER_PARENTESCOS_REJA_CLEANER,
    });
  };

  const guardarPasoDosRejaData = async (datos) => {
    dispatch({
      type: GUARDAR_PASO_DOS_REJA_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        `/jubilacion/reja/paso-dos`,
        datos
      );
      dispatch({
        type: GUARDAR_PASO_DOS_REJA_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, GUARDAR_PASO_DOS_REJA_ERROR);
    }
  };
  const limpiarGuardarPasoDosRejaData = () => {
    dispatch({
      type: GUARDAR_PASO_DOS_REJA_CLEANER,
    });
  };

  const obtenerEstimacionRejaData = async (id) => {
    dispatch({
      type: REJA_ESTIMACION_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(`/procedures/${id}/estimation`);
      dispatch({
        type: REJA_ESTIMACION_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, REJA_ESTIMACION_ERROR);
    }
  };
  const limpiarObtenerEstimacionRejaData = () => {
    dispatch({
      type: REJA_ESTIMACION_CLEANER,
    });
  };

  const obtenerPDFEstimacionRejaData = async (id) => {
    dispatch({
      type: REJA_PDF_ESTIMACION_LOADING,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(
        `/procedures/${id}/pdf-estimation`
      );
      dispatch({
        type: REJA_PDF_ESTIMACION_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, REJA_PDF_ESTIMACION_ERROR);
    }
  };
  const limpiarObtenerPDFEstimacionRejaData = () => {
    dispatch({
      type: REJA_PDF_ESTIMACION_CLEANER,
    });
  };

  const obtenerProcesoJubilacionData = async (tramite) => {
    dispatch({
      type: OBTENER_PROCESO_JUBILACION_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.get(
        `/jubilacion/obtener-tramite/${tramite}`
      );
      dispatch({
        type: OBTENER_PROCESO_JUBILACION_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, OBTENER_PROCESO_JUBILACION_ERROR);
    }
  };
  const limpiarObtenerProcesoJubilacionData = () => {
    dispatch({
      type: OBTENER_PROCESO_JUBILACION_CLEANER,
    });
  };

  const crearProcesoJubilacionData = async (datos) => {
    dispatch({
      type: CREAR_PROCESO_JUBILACION_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post("/procedures", datos);
      dispatch({
        type: CREAR_PROCESO_JUBILACION_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, CREAR_PROCESO_JUBILACION_ERROR);
    }
  };
  const limpiarCrearProcesoJubilacionData = () => {
    dispatch({
      type: CREAR_PROCESO_JUBILACION_CLEANER,
    });
  };

  const actualizarProcesoJubilacionData = async (datos) => {
    dispatch({
      type: ACTUALIZAR_PROCESO_JUBILACION_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post(
        "/jubilacion/actualizar-proceso",
        datos
      );
      dispatch({
        type: ACTUALIZAR_PROCESO_JUBILACION_EXITOSO,
        payload: respuesta.data,
      });
    } catch (error) {
      handleErrorsLocal(error, ACTUALIZAR_PROCESO_JUBILACION_ERROR);
    }
  };
  const limpiarActualizarProcesoJubilacionData = () => {
    dispatch({
      type: ACTUALIZAR_PROCESO_JUBILACION_CLEANER,
    });
  };

  const jubilacionPost = async (dato) => {
    dispatch({
      type: JUBILACION_POST_LOADIN,
      payload: true, // mandamos el loading al boton
    });
    try {
      const respuesta = await clienteAxios.post("/av-afiliado", { tipo: dato });
      dispatch({
        type: JUBILACION_POST_EXITOSO,
        payload: respuesta.data.r_menu,
      });
    } catch (error) {
      handleErrorsLocal(error, JUBILACION_POST_ERROR);
    }
  };

  const opcionesMenuPost = async () => {
    // dispatch({
    //   type: JUBILACION_POST_LOADIN,
    //   payload: true, // mandamos el loading al boton
    // });
    try {
      const respuesta = await clienteAxios.post("/menu");

      dispatch({
        type: MENU_DATA,
        payload: {
          opcionesMenu: respuesta.data.r_menu[0],
          procedureActives: respuesta.data.procedureActives, // Nueva variable
        },
      });
    } catch (error) {
      handleErrorsLocal(error, JUBILACION_POST_ERROR);
    }
  };

  const limpiarVariablesJubilacion = () => {
    dispatch({
      type: JUBILACION_CLEANER,
    });
  };
  const limpiarVariablesJubilacionParcial = () => {
    dispatch({
      type: JUBILACION_CLEANER_PARCIAL,
    });
  };

  const updateTypeBank = (data) => {
    dispatch({
      type: SEGUNDO_RETIRO_TYPE_BANK,
      payload: data,
    });
  };

  const updateTypeBankJL = (data) => {
    dispatch({
      type: JUBILACION_LEGAL_TYPE_BANK,
      payload: data,
    });
  };

  const updateTypeBankJR = (data) => {
    dispatch({
      type: REJA_TYPE_BANK,
      payload: data,
    });
  };

  const registerDataComercial = async (data) => {
    try {
      await clienteAxios.post("/captacion-datos", data);
    } catch (error) {
      console.log("registerDataComercial error : ", error);
      // handleErrorsLocal(error, OBTENER_CONTENIDO_PASO_JUBILACION_ERROR);
    }
  };

  const sendMailComercial = async (data) => {
    try {
      await clienteAxios.post("/enviar-correo-comercial", data);
    } catch (error) {
      console.log("sendMailComercial error : ", error);
    }
  };

  const [state, dispatch] = useReducer(JubilacionReducer, initialState);
  return (
    <JubilacionContext.Provider
      value={{
        resultadoJubilacionPost: state.resultadoJubilacionPost,
        mensajeAlertaJubilacionPost: state.mensajeAlertaJubilacionPost,
        loadingBtnJubilacionPost: state.loadingBtnJubilacionPost,
        opcionesMenu: state.opcionesMenu,
        procedureActives: state.procedureActives,
        jubilacionData: state.jubilacionData,
        jubilacionDataLoading: state.jubilacionDataLoading,
        createJubilacionData: state.createJubilacionData,
        createJubilacionDataLoading: state.createJubilacionDataLoading,
        updateProcessData: state.updateProcessData,
        updateProcessLoading: state.updateProcessLoading,
        legalData: state.legalData,
        legalDataLoading: state.legalDataLoading,
        createLegalData: state.createLegalData,
        createLegalDataLoading: state.createLegalDataLoading,
        contenidoTemaJubilacionLoading: state.contenidoTemaJubilacionLoading,
        contenidoTemaJubilacionData: state.contenidoTemaJubilacionData,
        guardarAvanceJuilacionLoading: state.guardarAvanceJuilacionLoading,
        guardarAvanceJuilacionData: state.guardarAvanceJuilacionData,
        rejaSgaValidarData: state.rejaSgaValidarData,
        rejaSgaValidarLoading: state.rejaSgaValidarLoading,
        rejaData: state.rejaData,
        rejaDataLoading: state.rejaDataLoading,
        createRejaData: state.createRejaData,
        createRejaDataLoading: state.createRejaDataLoading,
        temasAsesoriaRejaLoading: state.temasAsesoriaRejaLoading,
        temasAsesoriaRejaData: state.temasAsesoriaRejaData,
        guardarPaso1ResponseLoading: state.guardarPaso1ResponseLoading,
        guardarPaso1Response: state.guardarPaso1Response,
        guardarPaso2ResponseLoading: state.guardarPaso2ResponseLoading,
        guardarPaso2Response: state.guardarPaso2Response,
        relationshipsData: state.relationshipsData,
        relationshipsLoading: state.relationshipsLoading,
        saveStepTwoData: state.saveStepTwoData,
        saveStepTwoDataLoading: state.saveStepTwoDataLoading,
        guardarPaso3ResponseLoading: state.guardarPaso3ResponseLoading,
        guardarPaso3Response: state.guardarPaso3Response,
        marcoBonoDataLoading: state.marcoBonoDataLoading,
        marcoBonoData: state.marcoBonoData,
        estimacionRejaData: state.estimacionRejaData,
        estimacionRejaLoading: state.estimacionRejaLoading,
        estimacionPDFRejaLoading: state.estimacionPDFRejaLoading,
        estimacionPDFRejaData: state.estimacionPDFRejaData,
        guardarPaso4ResponseLoading: state.guardarPaso4ResponseLoading,
        guardarPaso4Response: state.guardarPaso4Response,
        guardarPaso5Response: state.guardarPaso5Response,
        guardarPaso5ResponseLoading: state.guardarPaso5ResponseLoading,
        marcoPensionDataLoading: state.marcoPensionDataLoading,
        marcoPensionData: state.marcoPensionData,
        guardarPaso6Response: state.guardarPaso6Response,
        guardarPaso6ResponseLoading: state.guardarPaso6ResponseLoading,
        guardarModalidad: state.guardarModalidad,
        guardarAPVf: state.guardarAPVf,
        guardarPaso7Response: state.guardarPaso7Response,
        guardarPaso7ResponseLoading: state.guardarPaso7ResponseLoading,
        jaoAccessData: state.jaoAccessData,
        jaoAccessLoading: state.jaoAccessLoading,
        jaoProcessData: state.jaoProcessData,
        jaoProcessLoading: state.jaoProcessLoading,
        jaoBeneficiariesLoading: state.jaoBeneficiariesLoading,
        jaoBeneficiariesData: state.jaoBeneficiariesData,
        saveJaoBeneficiariesLoading: state.saveJaoBeneficiariesLoading,
        saveJaoBeneficiariesData: state.saveJaoBeneficiariesData,
        jaoSaldosLoading: state.jaoSaldosLoading,
        jaoSaldosData: state.jaoSaldosData,
        saveJaoSaldosLoading: state.saveJaoSaldosLoading,
        saveJaoSaldosData: state.saveJaoSaldosData,
        saveJaoAvisoLoading: state.saveJaoAvisoLoading,
        saveJaoAvisoData: state.saveJaoAvisoData,
        jaoResultadoLoading: state.jaoResultadoLoading,
        jaoResultadoData: state.jaoResultadoData,
        accedeSegundoRetiroDataLoading: state.accedeSegundoRetiroDataLoading,
        accedeSegundoRetiroData: state.accedeSegundoRetiroData,
        mensajeAlerta: state.mensajeAlerta,
        typeBankSR: state.typeBankSR,
        typeBankJL: state.typeBankJL,
        typeBankJR: state.typeBankJR,
        jubilacionPost,
        resetear,
        limpiarVariablesJubilacion,
        limpiarVariablesJubilacionParcial,
        opcionesMenuPost,
        obtenerProcesoJubilacionData,
        limpiarObtenerProcesoJubilacionData,
        crearProcesoJubilacionData,
        limpiarCrearProcesoJubilacionData,
        crearProcesoRejaEtapa1,
        limpiarCrearProcesoRejaEtapa1,
        obtenerContenidoPasoReja,
        limpiarObtenerContenidoPasoReja,
        crearProcesoRejaEtapa2,
        limpiarCrearProcesoRejaEtapa2,
        crearProcesoRejaEtapa3,
        limpiarCrearProcesoRejaEtapa3,
        obtenerMarcoBonoReja,
        limpiarObtenerMarcoBonoReja,
        crearProcesoRejaEtapa4,
        limpiarCrearProcesoRejaEtapa4,
        crearProcesoRejaEtapa5,
        limpiarCrearProcesoRejaEtapa5,
        obtenerMarcoPensionReja,
        limpiarObtenerMarcoPensionReja,
        crearProcesoRejaEtapa6,
        limpiarCrearProcesoRejaEtapa6,
        guardarRejaEtapa6,
        crearProcesoRejaEtapa7,
        limpiarCrearProcesoRejaEtapa7,
        actualizarProcesoJubilacionData,
        limpiarActualizarProcesoJubilacionData,
        enviarRejaSgaValidador,
        limpiarEnviarRejaSgaValidador,
        obtenerProcesoLegalData,
        limpiarObtenerProcesoLegalData,
        crearProcesoLegalData,
        limpiarCrearProcesoLegalData,
        obtenerContenidoTemaJubilacion,
        limpiarObtenerContenidoTemaJubilacion,
        guardarAvanceTramiteJubilacion,
        limpiarGuardarAvanceTramiteJubilacion,
        obtenerProcesoRejaData,
        limpiarObtenerProcesoRejaData,
        crearProcesoRejaData,
        limpiarCrearProcesoRejaData,
        obtenerParentescosRejaData,
        limpiarObtenerParentescosRejaData,
        guardarPasoDosRejaData,
        limpiarGuardarPasoDosRejaData,
        obtenerEstimacionRejaData,
        limpiarObtenerEstimacionRejaData,
        obtenerPDFEstimacionRejaData,
        limpiarObtenerPDFEstimacionRejaData,
        obtenerAccesoJaoData,
        limpiarObtenerAccesoJaoData,
        obtenerProcesoJaoData,
        limpiarObtenerProcesoJaoData,
        obtenerBeneficiariosJaoData,
        limpiarObtenerBeneficiariosJaoData,
        guardarBeneficiariosJaoData,
        limpiarGuardarBeneficiariosJaoData,
        obtenerSaldosJaoData,
        limpiarObtenerSaldosJaoData,
        guardarSaldosJaoData,
        limpiarGuardarSaldosJaoData,
        guardarAvisoJaoData,
        limpiarGuardarAvisoJaoData,
        obtenerRespuestaEvaluacionJaoData,
        limpiarObtenerRespuestaEvaluacionJaoData,
        obtenerProcesoSegundoRetiroData,
        limpiarObtenerProcesoSegundoRetiroData,
        updateTypeBank,
        updateTypeBankJL,
        updateTypeBankJR,
        registerDataComercial,
        sendMailComercial,
      }}
    >
      {props.children}
    </JubilacionContext.Provider>
  );
};

export default MisDatosState;
