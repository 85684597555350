import React, { useEffect } from "react";
import { VistaEspera } from "./etapas/components";
import imgConfirmation from "../../../../assets/confirmacion.svg";

export default function JubilacionRejaMensualFin(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <VistaEspera
        imgLogo={imgConfirmation}
        title={`${sessionStorage.getItem(
          "nombreUsuario"
        )}, hemos concluido con tu solicitud de pensión mensual.`}
        description={
          <>
            En un plazo de 3 días útiles nos estaremos comunicando contigo para
            poder continuar con el proceso de pensión mensual por Jubilación
            Anticipada por Desempleo.
            <br />
            <br />
            El horario en el que te podemos llamar será de lunes a viernes de
            9:00 a.m. a 6:00 p.m. te pedimos estar atento en los próximos días.
          </>
        }
        btnLabel="Continuar"
        btnFunction={() =>
          props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion")
        }
      />
    </div>
  );
}
