import React, { useState, useContext, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { Progress } from "reactstrap";
import JubilacionContext from "../../../../../../context/jubilacion/jubilacionContext";
import MisDatosContext from "../../../../../../context/misdatos/misdatosContext";
import { Paso1, Paso2, Paso3, Paso4, Paso5, Paso6 } from "../../etapas";
import { MenuStep } from "../../../jubilacionReja/etapas/components";
import Icon from "../Icon";
import Page from "../Page";
import Card from "../Card";
import Appbar from "../Appbar";
import { useHistory } from "react-router-dom";
import CustomModal from "../../../../Components/custromModal/CustomModal";

import Elderly from "../../../../../../assets/icons/elderly.svg";
import Flag from "../../../../../../assets/icons/flag.svg";
import Buttons from "../../../../../../components/Buttons";
import {
  ModalActualizarDatos,
  ModalPensionC40,
  ModalPensionC40Final,
  ModalNoDatos,
  ModalValidacionDatos,
  ModalTramiteFinish,
} from "../Modals";
import useWindowSize from "../../../../../../hooks/useWindowSize";
import { size } from "../../../../../../utils/device";
import {
  ContainerView,
  ContentTitle,
  Contents,
  BoxNumber,
  BoxText,
  BoxCard,
  BoxLine,
  BoxNumCircle,
  BoxTextFunds,
  BoxFund,
} from "../../styles/ViewComponent";
const ViewComponent = (props) => {
  const [isOpenModalPensionC40, setIsOpenModalPensionC40] = useState(false);
  const [isOpenModalPensionC40Final, setIsOpenModalPensionC40Final] =
    useState(false);
  const [isOpenModalActualizarDatos, setIsOpenModalActualizarDatos] =
    useState(false);
  const [isOpenModalValidacionDatos, setIsOpenModalValidacionDatos] =
    useState(false);
  const [isOpenModalTramiteFinalizado, setIsOpenModalTramiteFinalizado] =
    useState(false);
  const [isOpenModalNoDatos, setIsOpenModalNoDatos] = useState(false);
  const { width } = useWindowSize();
  const [modalShowError, setModalShowError] = useState(false);
  const [textError, setTextError] = useState([]);
  const isSmallScreen = width <= size.tablet;
  const misDatosContext = useContext(MisDatosContext);
  const [showStepComponent, setShowStepComponent] = useState(true);
  const [isStart, setIsStart] = useState(false);
  const [currectStepNumber, setCurrectStepNumber] = useState(0);
  const [currectStepTitle, setCurrectStepTitle] = useState("");
  const [processId, setProcessId] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [stepsArray, setStepsArray] = useState([]);
  const [modalidad, setModalidad] = useState(null);
  const [apvf, setApvf] = useState(null);
  const jubilacionContext = useContext(JubilacionContext);
  const history = useHistory();
  const {
    legalData,
    createLegalData,
    createLegalDataLoading,
    crearProcesoLegalData,
    rejaSgaValidarLoading,
    enviarRejaSgaValidador,
    contenidoTemaJubilacionData,
  } = jubilacionContext;
  // console.log("LEGAL:", legalData);
  // console.log("BD: ", legalData?.data?.type_account_bank === 1 ? true : false);
  const { misDatosGet, obtenerMisDatosGet } = misDatosContext;
  useEffect(() => {
    obtenerMisDatosGet();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (legalData) {
      if (legalData.success) {
        if (legalData.data.status === "finished") {
          setIsOpenModalTramiteFinalizado(true);
        } else {
          if (legalData.data.has_procedure) {
            setIsStart(true);
          }
        }
      } else {
        setModalShowError(true);
        setTextError(legalData.errors);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createLegalData) {
      if (createLegalData.r_validacion === 1) {
        setShowStepComponent(false);
        setCurrectStepNumber(1);
        setCurrectStepNumber(legalData.data.step);
        setProcessId(createLegalData.data.procedure_id);
        setStepsArray(legalData.data.timeline);
        setIsOpenModalActualizarDatos(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createLegalData]);
  if (misDatosGet === null) return <p></p>;

  const handleShowMenu = () => {
    setShowMenu(true);
  };
  const getOutOfProcess = () => {
    history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion");
  };
  const handleCreate = () => {
    crearProcesoLegalData({
      type_of_procedure_id: 2,
    });
  };
  const renderStepComponent = () => {
    if (currectStepNumber === 1)
      return (
        <Paso1
          processId={processId}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
        />
      );
    else if (currectStepNumber === 2)
      return (
        <Paso2
          processId={processId}
          getOutOfProcess={getOutOfProcess}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
        />
      );
    else if (currectStepNumber === 3)
      return (
        <Paso3
          processId={processId}
          getOutOfProcess={getOutOfProcess}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
        />
      );
    else if (currectStepNumber === 4)
      return (
        <Paso4
          processId={processId}
          getOutOfProcess={getOutOfProcess}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
        />
      );
    else if (currectStepNumber === 5)
      return (
        <Paso5
          processId={processId}
          getOutOfProcess={getOutOfProcess}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
          setApvf={setApvf}
          setModalidad={setModalidad}
        />
      );
    else if (currectStepNumber === 6)
      return (
        <Paso6
          processId={processId}
          getOutOfProcess={getOutOfProcess}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
          history={history}
          contenidoTemaJubilacionData={contenidoTemaJubilacionData}
          modalidad={modalidad}
          apvf={apvf}
        />
      );
    else return <p></p>;
  };
  const renderViewComponent = () => {
    return (
      <div
        className="legal-container"
        style={{ margin: "auto", padding: "16px" }}
      >
        <div className="btn-step mb-3">
          <button id="btn-menu-legal" onClick={() => handleShowMenu()}>
            Ver pasos
          </button>
        </div>
        <div className="legal-header header-step">
          <Progress
            className="legal-progress-bar"
            color="success"
            value={currectStepNumber}
            max={7}
          />

          <h4>{currectStepTitle}</h4>
        </div>
        <div className="legal-body">{renderStepComponent()}</div>
        <MenuStep
          currectStepNumber={currectStepNumber}
          show={showMenu}
          stepArray={stepsArray}
          setShow={setShowMenu}
        />
      </div>
    );
  };

  const handleStart = () => {
    if (isStart) {
      if (legalData.pmo_validado === 1) {
        setIsOpenModalValidacionDatos(true);
      } else {
        if (localStorage.getItem("isDataUpdated").toString() === "true") {
          setShowStepComponent(false);
          setCurrectStepNumber(legalData.data.step);
          setProcessId(legalData.data.procedure_id);
          setStepsArray(legalData.data.timeline);
        } else {
          history.push(
            "/mi-habitat-digital/afiliado/jubilacion-legal/actualizacion-datos"
          );
        }
      }
    } else {
      if (localStorage.getItem("isDataUpdated").toString() === "true") {
        setIsOpenModalPensionC40(true);
      } else {
        history.push(
          "/mi-habitat-digital/afiliado/jubilacion-legal/actualizacion-datos"
        );
      }
    }
  };

  const AppbarComponent = showStepComponent
    ? isSmallScreen
      ? true
      : false
    : false;
  return (
    <Page>
      {AppbarComponent && (
        <Appbar title="Jubilación Legal" onHandleClick={getOutOfProcess} />
      )}

      {showStepComponent ? (
        <ContainerView>
          <ContentTitle>
            <Grid container>
              <Grid item xs={3} md={1}>
                <Icon url={Elderly} width={"64px"} height={"64px"} />
              </Grid>
              <Grid item xs={9} md={11}>
                <h4>Jubilación Legal</h4>
              </Grid>
            </Grid>
          </ContentTitle>
          <Grid container>
            <Grid item xs={12} md={8} className="HBT_JUBLEGAL_Divider">
              <Contents>
                <h4>Sobre esta modalidad</h4>
                <p>
                  Esta modalidad te permite usar tus fondos de Jubilación una
                  vez hayas cumplido los 65 años de edad.
                </p>
                {/* <p>
                  Al solicitar tu Jubilación Legal podrás retirar el 95.5% de
                  tus fondos, realizar retiros parciales o solicitar una pensión
                  mensual.
                </p> */}
              </Contents>
            </Grid>
            <Grid item xs={12} md={4}>
              <Contents>
                <h4>Pasos para acceder</h4>
                <Box>
                  <Card>
                    <BoxCard>
                      <BoxNumber>
                        <BoxNumCircle>1</BoxNumCircle>
                      </BoxNumber>
                      <BoxText>
                        Al cumplir 65 años, solicita tu jubilación Legal y
                        selecciona la modalidad de pensión o retiro que más se
                        adecue a ti.
                      </BoxText>
                    </BoxCard>
                  </Card>

                  <BoxTextFunds>
                    <BoxLine></BoxLine>
                    <BoxFund>
                      <Icon
                        url={Flag}
                        width={"32px"}
                        height={"32px"}
                        padding={"10px"}
                      />
                      <h4>Obtención de tus fondos</h4>
                    </BoxFund>
                  </BoxTextFunds>
                </Box>
              </Contents>
            </Grid>
          </Grid>

          <Contents>
            <Buttons size={"lg"} fullWidth={true} onClick={handleStart}>
              Solicitar
            </Buttons>
          </Contents>
        </ContainerView>
      ) : (
        renderViewComponent()
      )}

      <ModalPensionC40
        isOpen={isOpenModalPensionC40}
        setIsOpen={setIsOpenModalPensionC40}
        misDatosGet={misDatosGet}
        setIsOpenModalPensionC40Final={setIsOpenModalPensionC40Final}
        setIsOpenModalActualizarDatos={setIsOpenModalActualizarDatos}
        setIsOpenModalNoDatos={setIsOpenModalNoDatos}
        crearProcesoLegalData={handleCreate}
      />

      <ModalPensionC40Final
        isOpen={isOpenModalPensionC40Final}
        setIsOpen={setIsOpenModalPensionC40Final}
        enviarRejaSgaValidador={enviarRejaSgaValidador}
        rejaSgaValidarLoading={rejaSgaValidarLoading}
      />
      <ModalActualizarDatos
        isOpen={isOpenModalActualizarDatos}
        setIsOpen={setIsOpenModalActualizarDatos}
        misDatosGet={misDatosGet}
        crearProcesoLegalData={handleCreate}
        createLegalDataLoading={createLegalDataLoading}
      />
      <ModalNoDatos
        isOpen={isOpenModalNoDatos}
        setIsOpen={setIsOpenModalNoDatos}
      />
      <ModalTramiteFinish
        isOpen={isOpenModalTramiteFinalizado}
        setIsOpen={setIsOpenModalTramiteFinalizado}
      />
      <ModalValidacionDatos
        isOpen={isOpenModalValidacionDatos}
        setIsOpen={setIsOpenModalValidacionDatos}
      />
      <CustomModal
        showModal={modalShowError}
        className="beauty-modal"
        backdrop={"static"}
        title="¡Advertencia!"
        description={
          <div className="content-message-validation">
            <p>Estimado afiliado,</p>
            {textError &&
              textError.length > 0 &&
              textError.map((item) => {
                return <p className="item-message-validation">{item}</p>;
              })}
            <p className="message-remenber-validation mt-2">
              Si la información no es correcta, le solicitamos comunicarse con
              nuestro Call Center al 01 230-2200 lima o al 0801-14-224
              provincias para mayor detalle.{" "}
            </p>
          </div>
        }
        hasBtnPrimary={true}
        fnBtnPrimary={() => {
          // setModalShowError(false);
          getOutOfProcess();
        }}
        textBtnPrimary="Cerrar"
      />
    </Page>
  );
};

export default ViewComponent;
