import React, { useState } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import BreadCrumbsFL from "../BreadcrumbsFL";

const Header = styled(Box)`
  display: block;
  @media (min-width: 990px) {
    display: none;
  }
`;
const Body = styled(Box)`
  padding: ${(props) => props.paddingDesktop || '65px 16px 24px'};  /* Valor predeterminado para pantallas grandes */

  @media (max-width: 600px) {
    padding: ${(props) => props.paddingMobile || '65px 16px 24px'};  /* Valor configurable solo para pantallas móviles */
  }

  @media (min-width: 990px) {
    padding: ${(props) => props.paddingDesktop || '16px 24px'};  /* Valor configurable para pantallas grandes */
  }
`;
const TitleCard = styled.h3`
  ${(props) => props.theme.tipography.h4};
  padding: ${(props) => props.theme.spacing(0, 2)};
  margin-bottom: ${(props) => props.theme.spacing(2)};
  @media (min-width: 990px) {
    font-size: 20px;
    margin-bottom: 0;
  }
`;
const Index = (props) => {
  const { title, showHeader, children, identificador, paddingMobile, paddingDesktop, showTitle } = props;
  const [breadCrumb, setBreadCrumb] = useState(0);

  return (
    <Box>
      <Header show={showHeader}>
        {!identificador && !showTitle
          && <TitleCard>{title}</TitleCard>}

        {identificador && (
          <BreadCrumbsFL
            breadCrumb={breadCrumb}
            title_mobile={"Home"}
            title={"Fondo Libre"}
            rutas={[
              {
                id: 1,
                title: "Fondo Libre Habitat",
                // ruta: "",
              },
              {
                id: 1,
                title:
                  identificador === "aporte"
                    ? "Invertir"
                    : "Realizar un retiro",
                // ruta: "#/mi-habitat-digital/afiliado/estado-de-tramite",
              },
            ]}
          />
        )}
      </Header>
      <Body paddingMobile={paddingMobile} paddingDesktop={paddingDesktop}>{children}</Body>
    </Box>
  );
};

export default Index;
