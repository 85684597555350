import React, { useContext, useEffect, useRef, useState } from "react";
import { LoginForm } from "../index";
import Slider from "react-slick";

import AuthContext from "../../../../context/autenticacion/authContext";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import zIndex from "@mui/material/styles/zIndex";
import imgX from "../../../../assets/img/Sello-BCX.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useNotification } from "../../../../context/notificationbar/notificationBarContext";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const Section1 = (props) => {
  const { slides, onSubmit, loading, error } = props;
  const { barHeight } = useNotification();
  const authContext = useContext(AuthContext);
  const { dataBlock, limpiarVariablesAccountBlock } = authContext;
  const [isMobile, setIsMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(getWidth());
  const [isPlayed, setIsPlayed] = useState(true);
  let sliderRef = useRef(null);
  const myRef = useRef(null);

  // Función para calcular marginTop
  const calculateMarginTop = (barHeight, defaultValue) =>
    barHeight !== 0 ? defaultValue : 0;

  const pause = () => {
    if (isPlayed) {
      setIsPlayed(false);
      sliderRef.current.slickPause();
    } else {
      setIsPlayed(true);
      sliderRef.current.slickPlay();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
  };

  const assignTabIndex = () => {
    if (
      sliderRef.current &&
      sliderRef.current.innerSlider &&
      sliderRef.current.innerSlider.list
    ) {
      const dotButtons =
        sliderRef.current.innerSlider.list.parentNode.querySelectorAll(
          ".slick-dots button"
        );
      dotButtons.forEach((button, index) => {
        button.tabIndex = -1;
      });
    }
  };

  useEffect(() => {
    assignTabIndex();
  }, []);

  useEffect(() => {
    const resizeListener = () => {
      setWindowWidth(getWidth());
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [window.screen.width]);

  useEffect(() => {
    if (windowWidth > 980 && isMobile === true) {
      setIsMobile(false);
    } else if (windowWidth <= 980 && isMobile === false) setIsMobile(true);
  }, [windowWidth]);

  const renderReactComponent = (rootElement) => {
    import("react-dom").then((ReactDOM) => {
      ReactDOM.render(
        <div onClick={pause} style={{ cursor: "pointer" }} id="slidesPlay">
          {isPlayed ? (
            <PauseIcon fontSize="medium" sx={{ color: "#a40023" }} />
          ) : (
            <PlayArrowIcon fontSize="medium" sx={{ color: "#a40023" }} />
          )}
        </div>,
        rootElement
      );
    });
  };

  useEffect(() => {
    const renderPlayButton = () => {
      const list = document.querySelector("ul.slick-dots");

      // Verifica si la lista existe antes de modificarla
      if (list) {
        const listItem = document.createElement("li");

        // Renderiza el botón en React
        renderReactComponent(listItem);

        // Elimina el botón existente si ya está en la lista
        if (document.getElementById("slidesPlay") && list.lastChild) {
          const lastChild = list.lastChild;
          const slidesPlayDiv = lastChild.querySelector("div#slidesPlay");
          if (slidesPlayDiv) list.removeChild(list.lastChild);
        }

        // Agrega el nuevo botón a la lista
        list.appendChild(listItem);
      } else {
        console.warn("slick-dots no está disponible todavía.");
      }
    };

    // Espera un tiempo después del renderizado inicial para asegurar que `slick-dots` exista
    const timer = setTimeout(renderPlayButton, 500);

    // Limpia el timer cuando el componente se desmonta
    return () => clearTimeout(timer);
  }, [isPlayed, windowWidth]);


  /* REVSLIDER */
  const applyBackgroundImages = () => {
    const banners = document.querySelectorAll(".bg-baner-13");
    banners.forEach((banner) => {
      const bgUrl = banner.getAttribute("data-bg");
      if (bgUrl) {
        banner.style.backgroundImage = `url('${bgUrl}')`;
      }
    });

    const overlays = document.querySelectorAll(".overlay");
    overlays.forEach((overlay) => {
      const bgImage = overlay.getAttribute("data-bg-letra");
      if (bgImage) {
        overlay.style.backgroundImage = `url('${bgImage}')`;
      }
    });

    const legales = document.querySelectorAll(".legal");
    legales.forEach((legal) => {
      const bgLegal = legal.getAttribute("data-bg-legal");
      if (bgLegal) {
        legal.style.backgroundImage = `url('${bgLegal}')`;
      }
    });
  };

  // useEffect para aplicar imágenes cuando cambian las diapositivas o el estado de mobile/desktop
  useEffect(() => {
    applyBackgroundImages();
  }, [slides, isMobile]);

  /* fin REVSLIDER */

  // console.log('slide: ', slides);

  return (
    <section className="section1" ref={myRef}>
      <div className="banner-container">
        <Slider ref={sliderRef} {...settings}>
          {slides.map((slide) => {
            // Definir el background dinámico según el dispositivo
            const backgroundImage = isMobile
              ? slide.backgrounds?.mobile
              : slide.backgrounds?.desktop;

            // Validar que haya una imagen de fondo
            if (!backgroundImage) return null;
            // console.log('--------- ORDER ---------', slide.order);

            return (
              <div
                key={slide.id}
                className="banner-section bg-baner-13"
                data-bg={backgroundImage}
              >
                <div className="container-new-home banner-content">
                  <div className="banner-info">
                    {slide.layers?.map((layer) => {
                      // Definir estilos dinámicos
                      const layerStyles =
                        layer.styles?.[isMobile ? "mobile" : "desktop"] || {};
                      const positionStyles =
                        layer.position?.[isMobile ? "mobile" : "desktop"] || {};

                      // console.log({
                      //   titulo: layer.alias,
                      //   estilos: layerStyles,
                      //   posicion: positionStyles,
                      // });

                      // Renderizar tipos de capas según corresponda
                      switch (layer.alias.toLowerCase()) {
                        // TITULO PRINCIPAL
                        case "titulo":
                          if (layer.type === 'text') {
                            return (
                              <div className="box-baner-19">
                                <p className="txt-title"
                                  key={layer.id}
                                  style={{
                                    ...layerStyles,
                                    // ...positionStyles
                                  }}
                                  dangerouslySetInnerHTML={{ __html: layer.content }}
                                />
                              </div>
                            );
                          }
                          else if (layer.type === 'image') {
                            const imageBackground = layer.imageUrl;

                            if (!imageBackground) return null;

                            return (
                              <div className="overlay"
                                key={layer.id}
                                data-bg-letra={imageBackground}
                                style={{
                                  width: isMobile ? layer.styles?.mobile?.width || "auto" : layer.styles?.desktop?.width || "auto",
                                  height: isMobile ? layer.styles?.mobile?.height || "auto" : layer.styles?.desktop?.height || "auto",
                                }}
                              >

                              </div>
                            )
                          }
                          else {
                            return null;
                          }
                        default:
                          return null;
                      }
                    })}
                  </div>
                  {/* Capas fuera de banner-info */}
                  {slide.layers?.map((layer) => {
                    switch (layer.alias.toLowerCase()) {
                      // BOTON
                      case "cta":
                        if (layer.type === "button") {
                          return (
                            <button
                              key={layer.id}
                              className="btn-primary-new-home panel-traspaso btn-info"
                              style={{
                                zIndex: 100,
                                tabIndex: "-1",
                                // bottom: "-350px",
                              }}
                              onClick={() =>
                                layer.url && window.open(layer.url, "_blank")
                              }
                            >
                              {layer.content}
                            </button>
                          );
                        }
                        return null;

                      // FUENTE LEGAL
                      case "legal":
                        if (layer.type === "text") {
                          return (
                            <div
                              key={layer.id}
                              className="txt-subtitle"
                              dangerouslySetInnerHTML={{ __html: layer.content }}
                            ></div>
                          );
                        } else if (layer.type === "image") {
                          const imageLegal = layer.imageUrl;
                          if (!imageLegal) return null;

                          return (
                            <div
                              className="legal"
                              key={layer.id}
                              data-bg-legal={imageLegal}
                              style={{
                                width: isMobile
                                  ? layer.styles?.mobile?.width || "auto"
                                  : layer.styles?.desktop?.width || "auto",
                                height: isMobile
                                  ? layer.styles?.mobile?.height || "auto"
                                  : layer.styles?.desktop?.height || "auto",
                              }}
                            ></div>
                          );
                        }
                        return null;
                      default:
                        return null;
                    }
                  })}
                </div>
              </div>
            );
          })}
        </Slider>

        {isMobile && (
          <div
            onClick={pause}
            style={{ cursor: "pointer" }}
            className="slideMobile"
          >
            {isPlayed ? (
              <PauseIcon fontSize="medium" sx={{ color: "#ffffff" }} />
            ) : (
              <PlayArrowIcon fontSize="medium" sx={{ color: "#ffffff" }} />
            )}
          </div>
        )}

        <div className="desktop-login">
          <LoginForm
            sendRequest={onSubmit}
            loading={loading}
            error={error}
            dataBlock={dataBlock}
            limpiarVariablesAccountBlock={limpiarVariablesAccountBlock}
          />
        </div>
      </div>
    </section>
  );
};

export default Section1;