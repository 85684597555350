import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    useTheme,
    useMediaQuery,
    Grid,
    Paper,
    Typography,
    Button,
    Checkbox,
    FormControlLabel,
    Dialog,
    Slide,
    DialogContent,
    DialogTitle,
    IconButton,
    DialogActions,
} from "@mui/material";
import ReactGa from "react-ga";
import { Row, Col, Modal, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";
import "./index.css";
// ASSETS
import imgJao from "../../assets/tipo_jao.svg";
import imgPmo from "../../assets/tipo_pmo.svg";
import ICON_INFO from "../../assets/new_help.svg";
import imgNuts from "../../assets/nuts.svg";
// COMPONENTES/MODALS
import OtrasOpcionesJubilacionCard from "./Components/OtrasOpcionesJubilacionCard";
import MessageSnack from "../../components/MessageSnack";

import CustomModal from "./Components/custromModal/CustomModal";
import ModalExitoso from "./ModalExitoso";
import ModalFilter from "./ModalFilter";
import ModalMessage from "./ModalMessage";
import ModalAlertaAgencia from "../../components/ModalAlertaAgencia";
import Texto from "./Components/Texto";
import { Page, CardContainer } from "../../components";
import Loading from "../../components/Loading/index.jsx";
// CONTEXT
import AuthContext from "../../context/autenticacion/authContext";
import MisDatosContext from "../../context/misdatos/misdatosContext";
import ModalContext from "../../context/modal/modalContext";
import JubilacionContext from "../../context/jubilacion/jubilacionContext";

const Title = styled.div`
  & h1 {
    ${(props) => props.theme.tipography.h4};
    text-align: center;
  }
  & p {
    margin-bottom: 0;
  }
`;

const ContentCheckValidacion = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: ${(props) => props.theme.spacing(2)};
`;
const OtrasOpcionesJubilacion = (props) => {
    /* CONTEXT */
    const authContext = useContext(AuthContext);
    const { actualizarTimer } = authContext;

    const misDatosContext = useContext(MisDatosContext);
    const jubilacionContext = useContext(JubilacionContext);
    const {
        misDatosGet,
        mensajeAlertaDatosGet,
        obtenerMisDatosGet,
        limpiarVariablesMisDatosParcial,
        isDataUpdated,
    } = misDatosContext;

    const {
        /* MENU */
        opcionesMenuPost,
        opcionesMenu,
        /* JAO */
        jaoAccessData,
        jaoAccessLoading,
        obtenerAccesoJaoData,

        mensajeAlerta,
    } = jubilacionContext;
    const modalContext = useContext(ModalContext);
    const { setMessage } = modalContext;
    const mediaScreen = useMediaQuery("(max-width:1364px)");

    /* MODALS */
    const [confirmacionInfo, setConfirmacionInfo] = useState(null);
    const [modalExitoso, setModalExitoso] = useState(null);
    const [modalCelular, setModalCelular] = useState(null);
    const [modalMessage, setModalMessage] = useState(null);
    const [modalAlerta, setModalAlerta] = useState(null);
    const [modalFilter, setModalFilter] = useState(null);
    const [modalShowError, setModalShowError] = useState(false);
    const [textError, setTextError] = useState([]);
    const [opcionSeleccionada, setOpcionSeleccionada] = useState(null);
    /*JAO */
    const [modalJAO, setModalJAO] = useState(false);
    const [modalIpcJAOError, setModalIpcJAOError] = useState(false);

    const [sendRequest, setSendRequest] = useState(false);

    const [generalErrorModalIsOpen, setGeneralErrorModalIsOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        opcionesMenuPost();
        return () => {
            // console.log("EJECUTANDOSE");
            document.body.removeAttribute("style");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mensajeAlertaDatosGet) {
            mostrarMensaje(mensajeAlertaDatosGet);
        } else {
            ReactGa.pageview(window.location.href);
            obtenerMisDatosGet();
        }

        return () => {
            limpiarVariablesMisDatosParcial();
            actualizarTimer();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mensajeAlertaDatosGet]);

    useEffect(() => {
        if (mensajeAlerta) mostrarMensaje(mensajeAlerta);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mensajeAlerta]);

    useEffect(() => {
        if (jaoAccessData && sendRequest) {
            if (jaoAccessData.success) setModalJAO(!modalJAO);
            else {
                if (jaoAccessData.type === "icon") {
                    setModalIpcJAOError(!modalIpcJAOError);
                    setTextError([jaoAccessData.errors]);
                } else {
                    setModalShowError(!modalShowError);
                    setTextError(jaoAccessData.errors);
                }
            }
            setSendRequest(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jaoAccessData]);

    const mostrarMensaje = (mensaje) => {
        setMessage(mensaje);
    };

    if (mensajeAlertaDatosGet !== null || mensajeAlerta !== null) return <p></p>;
    if (misDatosGet === null || opcionesMenu === null) return <Loading />;

    const closeBtn = () => {
        setConfirmacionInfo(null);
    };

    const closeModalExitoso = () => () => {
        setModalExitoso(null);
    };

    const closeModalFilter = () => {
        setModalFilter(null);
        if (!misDatosGet.r_persona.r_datos.r_celular) {
            setModalCelular(true);
        }
        setConfirmacionInfo(opcionSeleccionada);
    };
    const onCloseBtnFilter = () => {
        setModalFilter(null);
    };

    const closeModalAlerta = () => {
        if (Number(opcionSeleccionada) === 6 || Number(opcionSeleccionada) === 5) {
            setModalFilter(true);
        } else {
            setModalAlerta(null);
            if (!misDatosGet.r_persona.r_datos.r_celular) {
                setModalCelular(true);
            }
            setConfirmacionInfo(opcionSeleccionada);
        }
    };
    const closeModalMessage = () => {
        setModalMessage(null);
    };
    const openModalMessage = () => {
        setModalMessage(true);
        setModalFilter(null);
    };
    const openModalAlertaX = () => {
        setModalFilter(null);
        setModalAlerta(true);
    };
    const closeModalAlertaX = () => () => {
        setModalAlerta(null);
    };

    const showOtrasOpciones = {
        showPmo: opcionesMenu?.r_segunda_decision !== "1" && opcionesMenu?.r_pmo !== "0",
        showJao: opcionesMenu?.r_segunda_decision !== "1" && opcionesMenu?.r_jao !== "0",
    };

    return (
        <Page title="Otras Opciones de Jubilación" paddingMobile="0" showTitle="false">
            <CardContainer>
                <div className="otras-opciones-container">
                    <h1 className="otras-opciones-title">Otras opciones de Jubilación</h1>
                    <p className="otras-opciones-subtitle">Realízalo 100% digital.</p>
                    <div className="otras-opciones-grid">
                        {/* Mostrar opción de Jubilación Anticipada Ordinaria (JAO) */}
                        {showOtrasOpciones.showJao && (
                            <OtrasOpcionesJubilacionCard
                                title="Jubilación Anticipada Ordinaria"
                                description="Empieza una pre-evaluación opcional y descubre si calificas a esta modalidad de Jubilación."
                                img={imgJao}
                                buttonLabel="Pre-evaluación"
                                onClick={() => {
                                    obtenerAccesoJaoData();
                                    setSendRequest(true);
                                }}
                                disabled={jaoAccessLoading}
                            />
                        )}
                        {/* Mostrar opción de Establecer Pensión Objetivo (PMO) */}
                        {showOtrasOpciones.showPmo && (
                            <OtrasOpcionesJubilacionCard
                                title="Establecer Pensión Objetivo"
                                description="Establece el monto de pensión que te gustaría tener cuando alcances la edad legal de jubilación."
                                img={imgPmo}
                                buttonLabel="Establecer ahora"
                                onClick={() => {
                                    props.history.push(
                                        "/mi-habitat-digital/afiliado/jubilacion-pmo/tramite"
                                    );
                                }}
                            />
                        )}
                    </div>
                    <MessageSnack icon={ICON_INFO} bgAlert="#F8F9FB" alt="ICON_INFO" marginTop="1.2em">
                        <ul className="note-list">
                            <li className="note-item">
                                <b>¿Tienes dudas?</b>
                            </li>
                            <li className="note-item-subtitle">
                                Si deseas obtener más información sobre los diferentes tipos de
                                jubilación y sus requisitos, <a href="https://www.afphabitat.com.pe/pensiones/#tipos-de-pension-de-jubilacion" className="note-link" target="_blank" rel="noopener noreferrer">haz clic aquí</a>.
                            </li>
                        </ul>
                    </MessageSnack>
                </div>
            </CardContainer>

            {/* MODALES */}
            {modalAlerta && (
                <ModalAlertaAgencia
                    dispositivo="desktop"
                    buttonCloseTop={true}
                    titulo={"!Recuerda que!"}
                    view={"Jubilación"}
                    dato={opcionSeleccionada}
                    closeModal={closeModalAlertaX}
                    closeBtn={closeModalAlerta}
                    componenteInfo={<Texto opcion={opcionSeleccionada} />}
                // componenteInfo={}
                />
            )}
            {modalFilter && (
                <ModalFilter
                    dispositivo="desktop"
                    buttonCloseTop={true}
                    info={modalFilter}
                    titulo={"Validación de jubilación"}
                    dato={opcionSeleccionada}
                    openModalAlertaX={openModalAlertaX}
                    openModalMessage={openModalMessage}
                    closeBtn={onCloseBtnFilter}
                    closeModal={closeModalFilter}
                />
            )}

            {modalMessage && (
                <ModalMessage
                    dispositivo="desktop"
                    buttonCloseTop={true}
                    titulo={"Solicitud Registrada"}
                    closeModal={closeModalMessage}
                />
            )}
            {modalExitoso && (
                <ModalExitoso
                    dispositivo="desktop"
                    buttonCloseTop={true}
                    info={modalExitoso}
                    history={props}
                    titulo={"Siguiente Paso"}
                    closeBtn={closeBtn}
                    closeModal={closeModalExitoso}
                />
            )}

            <CustomModal
                showModal={modalCelular}
                className="beauty-modal"
                backdrop={"static"}
                fnCloseIconBtn={() => {
                    setModalCelular(!modalCelular);
                }}
                title="Necesitas actualizar tus datos"
                description={
                    <p>
                        Por favor, actualiza tu número de celular para continuar con la
                        solicitud y poder contactarte.
                    </p>
                }
                hasBtnPrimary={true}
                fnBtnPrimary={() =>
                    props.history.push(
                        "/mi-habitat-digital/afiliado/informacion/datos-personales"
                    )
                }
                textBtnPrimary="Actualizar"
                hasBtnSecondary={true}
                fnBtnSecondary={() => setModalCelular(!modalCelular)}
                textBtnSecondary="Cancelar"
            />

            <CustomModal
                showModal={modalJAO}
                className="beauty-modal"
                backdrop={"static"}
                fnCloseIconBtn={() => {
                    setModalJAO(!modalJAO);
                }}
                title="Pre-evaluación de Jubilación Anticipada"
                description={
                    <p>
                        <strong>{sessionStorage.getItem("nombreUsuario")}</strong>, si estás
                        interesado en saber si accedes a la Jubilación Anticipada Ordinaria,
                        esta pre-avaluación te ayudará a saber si cumples con los
                        principales requisitos.
                    </p>
                }
                hasBtnPrimary={true}
                fnBtnPrimary={() => {
                    props.history.push(
                        "/mi-habitat-digital/afiliado/jubilacion-jao/info"
                    );
                }}
                textBtnPrimary="Continuar"
                hasBtnSecondary={true}
                fnBtnSecondary={() => {
                    setModalJAO(!modalJAO);
                }}
                textBtnSecondary="Lo realizaré luego"
            />

            <Modal
                isOpen={modalIpcJAOError}
                className="beauty-modal"
                backdrop="static"
                centered
            >
                <div className="modal-close">
                    <i
                        className="fa fa-times-circle-o"
                        onClick={() => {
                            setModalIpcJAOError(!modalIpcJAOError);
                        }}
                    ></i>
                </div>
                <div id="modal-header">
                    <img src={imgNuts} alt="nuts" />
                    <h5>¡Ocurrió un problema!</h5>
                </div>
                <ModalBody>{<p>{textError ? textError[0] : ""}</p>}</ModalBody>
                <ModalFooter>
                    <Row className="footer-row custom-total-buttons">
                        <Col className="footer-col-btn">
                            <button
                                className="btn-modal-primary"
                                onClick={() => {
                                    setModalIpcJAOError(!modalIpcJAOError);
                                }}
                            >
                                Volver
                            </button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </Page>

    );
};

export default OtrasOpcionesJubilacion;
