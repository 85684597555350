import React, { useEffect, useContext } from "react";
import { VistaEspera } from "../jubilacionReja/etapas/components";
import imgConfirmation from "../../../../assets/confirmacion.svg";
import AuthContext from "../../../../context/autenticacion/authContext";
import moment from "moment";

export default function JubilacionLegalMensualFin(props) {
  const authContext = useContext(AuthContext);
  const { birthday } = authContext;
  const client = localStorage.getItem("client");
  const docNum = localStorage.getItem("doc_num");
  const parseClient = JSON.parse(client);
  const age = moment().diff(birthday, "years");
  useEffect(() => {
    loadNPS();
    window.scrollTo(0, 0);
  }, []);

  const loadNPS = () => {
    const filterAge =
      age < 25
        ? "Menores de 25"
        : age >= 25 && age < 35
          ? "25 A 34"
          : age >= 35 && age < 51
            ? "35 a 51"
            : age >= 51 && age < 65
              ? "51 a 65"
              : "Mayores de 65";

    window.dispatchEvent(
      new CustomEvent("smile/user-context/update", {
        detail: {
          tipo_de_tramite: "Trámite de Jubilación por edad legal / pension",
          nombre_de_usuario: parseClient.r_primer_nombre,
          apellido_de_usuario: parseClient.r_primer_apellido,
          documento_de_identidad: docNum,
          correo: parseClient.r_correo,
          telefono: parseClient.r_celular,
          segmento: parseClient.r_segmento,
          grupo_de_edad: filterAge,
        },
      })
    );
    window.dispatchEvent(
      new CustomEvent("smile/feedback/show-poll", { detail: { id: 1303 } })
    );
  };

  return (
    <VistaEspera
      imgLogo={imgConfirmation}
      title={`${sessionStorage.getItem(
        "nombreUsuario"
      )}, hemos concluido con tu solicitud de pensión mensual.`}
      description={
        <>
          Una vez nos envíes los documentos solicitados realizaremos la
          validación de estos y deberás estar a la espera de nuestra
          comunicación vía llamada o correo para indicarte las observaciones y/o
          coordinar la fecha de la cita para iniciar el trámite. <br />
          <br />
          Recuerda mantener tus datos actualizados para lograr un contacto
          efectivo.{" "}
        </>
      }
      btnLabel="Continuar"
      btnFunction={() =>
        props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion")
      }
    />
  );
}
