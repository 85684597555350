import React, { useState, useEffect, useContext } from "react";
import JubilacionContext from "../../../../context/jubilacion/jubilacionContext";
import ModalContext from "../../../../context/modal/modalContext";
import { Progress } from "reactstrap";
import { Paso1, Paso2, Paso4 } from "./etapas";
import { MenuStep } from "../jubilacionReja/etapas/components";
import Loading from "../../../../components/Loading/index.jsx";
import "./index.css";
import Grid from "@mui/material/Grid";

export default function SegundoRetiroWorkflow(props) {
  const [currectStepNumber, setCurrectStepNumber] = useState(0);
  const [currectStepTitle, setCurrectStepTitle] = useState("");
  const [processId, setProcessId] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [stepsArray, setStepsArray] = useState([]);
  const [modalidad, setModalidad] = useState(true);
  const [apvf, setApvf] = useState(false);

  const jubilacionContext = useContext(JubilacionContext);
  const {
    accedeSegundoRetiroData,
    accedeSegundoRetiroDataLoading,
    obtenerProcesoSegundoRetiroData,
    limpiarObtenerProcesoSegundoRetiroData,
    mensajeAlerta,
  } = jubilacionContext;
  // console.log(
  //   "accedeSegundoRetiroData",
  //   accedeSegundoRetiroData?.data?.type_account_bank === 1 ? true : false
  // );
  const modalContext = useContext(ModalContext);
  const { setMessage } = modalContext;
  const mostrarMensaje = (mensaje) => {
    setMessage(mensaje);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    obtenerProcesoSegundoRetiroData();
    return () => {
      limpiarObtenerProcesoSegundoRetiroData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mensajeAlerta) mostrarMensaje(mensajeAlerta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mensajeAlerta]);

  useEffect(() => {
    if (accedeSegundoRetiroData) {
      if (accedeSegundoRetiroData.success) {
        if (
          accedeSegundoRetiroData.data.status === "in-process" ||
          accedeSegundoRetiroData.data.status === "pending"
        ) {
          setProcessId(accedeSegundoRetiroData.data.procedure_id);
          setStepsArray(accedeSegundoRetiroData.data.timeline);
          setCurrectStepNumber(accedeSegundoRetiroData.data.step);
        } else props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion");
      } else {
        props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accedeSegundoRetiroData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currectStepNumber]);

  const getOutOfProcess = () => {
    props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion");
  };
  const handleShowMenu = () => {
    setShowMenu(true);
  };

  const renderStepComponent = () => {
    if (currectStepNumber === 1)
      return (
        <Paso1
          processId={processId}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
        />
      );
    else if (currectStepNumber === 2)
      return (
        <Paso2
          processId={processId}
          getOutOfProcess={getOutOfProcess}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
        />
      );
    else if (currectStepNumber === 3)
      return (
        <Paso4
          processId={processId}
          getOutOfProcess={getOutOfProcess}
          setCurrectStepNumber={setCurrectStepNumber}
          setCurrectStepTitle={setCurrectStepTitle}
          history={props.history}
          modalidad={modalidad}
          apvf={apvf}
          setApvf={setApvf}
        />
      );
    else return <p></p>;
  };

  if (mensajeAlerta !== null) return <p></p>;
  if (accedeSegundoRetiroDataLoading || processId === null) return <Loading />;

  return (
    <Grid container p={2} justifyContent="center">
      <div className="second-withdrawal-container">
        <div className="btn-step mb-3">
          <button onClick={() => handleShowMenu()}>Ver pasos</button>
        </div>
        <div className="second-withdrawal-header header-step">
          <Progress
            className="second-withdrawal-progress-bar"
            color="success"
            value={currectStepNumber}
            max={3}
          />
          <h4>PASO {currectStepNumber}</h4>
          <h5>{currectStepTitle}</h5>
        </div>
        <div className="second-withdrawal-body">{renderStepComponent()}</div>
        <MenuStep
          currectStepNumber={currectStepNumber}
          show={showMenu}
          stepArray={stepsArray}
          setShow={setShowMenu}
        />
      </div>
    </Grid>
  );
}
