import React from "react";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Label } from "../../components";
import { Link } from "react-router-dom";

import Divider from "@mui/material/Divider";
import styled from "styled-components";
import ArrowRight from "../../assets/icons/akar-icons_chevron-right.svg";

const ContentCard = styled(CardContent)`
  && {
    background: linear-gradient(
      to right,
      #ffffff 53%,
      #edeff0 71%,
      #edeff0 78%
    );
    padding-bottom: 0px !important;
    padding: ${(props) => props.theme.spacing(0)};

    border-radius: 7px;
    box-shadow: 0px 0px 6px 1px rgb(1 1 1 / 25%);
    margin-bottom: 16px;
    margin-right: 0px !important;
  }
`;
const DividerCard = styled(Divider)`
  margin-top: -16px;
  margin-bottom: -16px;
`;
const ContainerCard = styled(CardContent)`
  padding: 0 16px;
`;
const TitleCard = styled(Typography)`
  && {
    ${(props) => props.theme.tipography.h2};
    color: ${(props) => props.theme.palette.secondary.main};
    margin-bottom: ${(props) => props.theme.spacing()};
  }
`;
const AmountCard = styled(Typography)`
  && {
    ${(props) => props.theme.tipography.h1};
    margin-bottom: ${(props) => props.theme.spacing()};
  }
`;
const SubTitleCard = styled(Typography)`
  && {
    ${(props) => props.theme.tipography.h3};
    margin-bottom: 0;
    color: ${(props) => props.theme.palette.grayscale.light};
  }
`;
const ImageCard = styled.img`
  && {
    position: absolute;
    z-index: -1;
    right: ${(props) => props.theme.spacing(1)};
    transform: translateY(50%);
  }
`;

const Button = (props) => {
  const { title, amount, subtitle, icon } = props;

  const style = {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right center",
    position: "relative",
    zIndex: 0,
  };

  return (
    <ContentCard sx={{ display: "flex", width: 328 }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          style={style}
          sx={{ display: "flex", flexDirection: "row", width: 279 }}
        >
          <ContainerCard>
            <TitleCard component="div">{title}</TitleCard>
            <AmountCard color="text.secondary" component="div">
              {amount}
            </AmountCard>
            <SubTitleCard color="text.secondary" component="div">
              {subtitle}
            </SubTitleCard>
          </ContainerCard>
          <Label ground="#48C180" color="#ffffff">
            fondo 2
          </Label>
          <ImageCard src={icon} />
        </Box>
        <DividerCard orientation="vertical" flexItem />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pb: 1,
            my: "auto",
            width: 48,
          }}
        >
          <IconButton
            aria-label="previous"
            sx={{
              pr: 0,
            }}
            component={Link}
            to="/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion"
          >
            <img src={ArrowRight} alt="imagen flecha a la derecha" />
          </IconButton>
        </Box>
      </Box>
    </ContentCard>
  );
};

export default Button;
