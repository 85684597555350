import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button } from "reactstrap";
import CustomModal from "../../../Components/custromModal/CustomModal";
import MisDatosContext from "../../../../../context/misdatos/misdatosContext";
import Loading from "../../../../../components/Loading/index.jsx";
import JubilacionContext from "../../../../../context/jubilacion/jubilacionContext";
import PhoneGreenIcon from "../../../../../assets/phone-green-icon.png";
import CallIcon from "../../../../../assets/call.svg";
import MailIcon from "../../../../../assets/mail.svg";
import CastIcon from "../../../../../assets/cast.svg";
import "./index.css";

export default function Info(props) {
  const [showRejaModal, setshowRejaModal] = useState(false);
  const [showModalActualizarDatos, setShowModalActualizarDatos] =
    useState(false);
  const [showModalActilizarCelular, setShowModalActilizarCelular] =
    useState(false);
  const [alreadyContributeModal, setAlreadyContributeModal] = useState(false);

  const misDatosContext = useContext(MisDatosContext);
  const {
    misDatosGet,
    mensajeAlertaDatosGet,
    obtenerMisDatosGet,
    limpiarVariablesMisDatosParcial,
  } = misDatosContext;

  const jubilacionContext = useContext(JubilacionContext);
  const { jaoAccessData } = jubilacionContext;
  useEffect(() => {
    window.scrollTo(0, 0);

    obtenerMisDatosGet();
    if (jaoAccessData === null)
      props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion");
    else if (!jaoAccessData.success)
      props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion");
    else if (jaoAccessData.data.access_reja) setshowRejaModal(true);

    return () => {
      limpiarVariablesMisDatosParcial();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shortStringWithPoints = (value) => {
    const maxLength = 23;
    if (value.length <= maxLength) return value;
    return `${value.substr(0, maxLength)}...`;
  };

  if (mensajeAlertaDatosGet !== null) return <p></p>;
  if (misDatosGet === null) return <Loading />;

  return (
    <Row className="container-jao">
      <Col xl="8" lg="8" md="8">
        <div className="info-jao">
          <h1>Pre-evaluación de Jubilación Anticipada</h1>
          <p id="info-text">
            Recuerda que esta pre-evaluación es de carácter informativo y no da
            inicio a una solicitud de jubilación.
            <br />
            <br />
            Antes de empezar, ten en cuenta las siguientes consideraciones:
          </p>

          <div className="content-requisitos mb-4">
            <div className="whitecard-block requisitos-card mb-4">
              <h4>Aportes realizados</h4>
              <p>
                Debes contar con al menos <strong>72 meses de aportes</strong>{" "}
                durante los últimos 120 meses (10 años).
              </p>
            </div>

            <div className="whitecard-block requisitos-card mb-4">
              <h4>Trabajadores independientes</h4>
              <p>
                En esta pre-evaluación no vamos considerar{" "}
                <strong>tus aportes como independiente</strong>.
                <br />
                <span onClick={() => setAlreadyContributeModal(true)}>
                  ¿Quieres que se consideren en esta evaluación?
                </span>
              </p>
            </div>

            <div id="independent-workers" className="requisitos-card">
              <div className="whitecard-block">
                <h4>Pensión estimada</h4>
                <p>
                  Tu pensión calculada debe ser{" "}
                  <strong>
                    por lo menos el 40% del promedio de las remuneraciones
                  </strong>{" "}
                  que has percibido durante los últimos 10 años, actualizadas
                  según el IPC (Índice de precios del Consumidor) y deduciendo
                  las gratificaciones de julio y diciembre.
                </p>
              </div>
              {/* <span className="mt-2">*IPC Índice de precios del consumidor.</span> */}
            </div>
          </div>
          <p className="mb-4">
            Esta pre-evaluación se realizará con los datos que tenemos
            actualmente. En caso no cumplas con la pensión estimada, podrás
            realizar un aporte para alcanzar la pensión requerida.
            <br />
            <br />
            Si vuelves a realizar esta pre-evaluación,{" "}
            <strong>el resultado podría variar</strong>.
          </p>

          <div className="container-jao-buttons">
            <Button
              className="mb-4 btn-modal-primary"
              onClick={() => {
                //setShowModalActualizarDatos(true);

                if (misDatosGet.r_persona.r_datos.r_celular)
                  setShowModalActualizarDatos(true);
                else setShowModalActilizarCelular(true);
              }}
            >
              Iniciar
            </Button>
            <Button
              className="mb-4 btn-modal-secondary"
              onClick={() =>
                props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion")
              }
            >
              Volver
            </Button>
          </div>
        </div>

        <CustomModal
          showModal={showModalActualizarDatos}
          className="beauty-modal"
          toggle={() => {
            setShowModalActualizarDatos(!showModalActualizarDatos);
          }}
          fnCloseIconBtn={() => {
            setShowModalActualizarDatos(!showModalActualizarDatos);
          }}
          title="Confirma tus datos de contacto"
          description={
            <>
              <div className="user-data">
                <div className="field mb-2">
                  <span className="label">Correo personal:</span>
                  <span className="data">
                    {misDatosGet.r_persona.r_datos.r_correo
                      ? misDatosGet.r_persona.r_datos.r_correo
                      : "No registrado"}
                  </span>
                </div>

                <div className="field">
                  <span className="label">Celular:</span>
                  <span className="data">
                    {misDatosGet.r_persona.r_datos.r_celular
                      ? misDatosGet.r_persona.r_datos.r_celular
                      : "No registrado"}
                  </span>
                </div>
              </div>
            </>
          }
          hasBtnPrimary={true}
          fnBtnPrimary={() =>
            props.history.push(
              "/mi-habitat-digital/afiliado/informacion/datos-personales"
            )
          }
          textBtnPrimary="Actualizar datos"
          hasBtnSecondary={true}
          fnBtnSecondary={() =>
            props.history.push(
              "/mi-habitat-digital/afiliado/jubilacion-jao/proceso"
            )
          }
          textBtnSecondary="Confirmar datos"
        />

        <CustomModal
          showModal={showModalActilizarCelular}
          className="beauty-modal"
          backdrop={"static"}
          fnCloseIconBtn={() => {
            setShowModalActilizarCelular(!showModalActilizarCelular);
          }}
          title="Habitat te informa"
          description={
            <p>
              Hemos detectado que no tienes actualizado algunos datos, para
              acceder a esta opción, te pedimos actualizar tu número de celular
              para podernos contactar contigo.
            </p>
          }
          hasBtnPrimary={true}
          fnBtnPrimary={() =>
            props.history.push(
              "/mi-habitat-digital/afiliado/informacion/datos-personales"
            )
          }
          textBtnPrimary="Actualizar"
          hasBtnSecondary={true}
          fnBtnSecondary={() =>
            setShowModalActilizarCelular(!showModalActilizarCelular)
          }
          textBtnSecondary="Cancelar"
        />

        <CustomModal
          showModal={showRejaModal}
          className="beauty-modal"
          backdrop={"static"}
          fnCloseIconBtn={() => {
            setshowRejaModal(!showRejaModal);
          }}
          title="Recuerda que..."
          description={
            <p>
              Actualmente calificas a la opción de Jubilación Anticipada por
              Desempleo (REJA). Con ella puedes solicitar el retiro de hasta el
              95.5% de tu fondo o recibir una pensión. Es 100% digital y puedes
              hacerlo desde tu Agencia Virtual.
            </p>
          }
          hasBtnPrimary={true}
          fnBtnPrimary={() =>
            props.history.push("/mi-habitat-digital/afiliado/jubilacion/solicitud-de-jubilacion")
          }
          textBtnPrimary="Ir a reja"
          hasBtnSecondary={true}
          fnBtnSecondary={() => setshowRejaModal(!showRejaModal)}
          textBtnSecondary="Seguir aquí"
        />
        {jaoAccessData.data.executive !== null ? (
          <CustomModal
            showModal={alreadyContributeModal}
            className="beauty-modal"
            backdrop={"static"}
            fnCloseIconBtn={() => {
              setAlreadyContributeModal(!alreadyContributeModal);
            }}
            title="¿Has realizado aportes como independiente?"
            description={
              <>
                <p className="mb-4">
                  Si quieres que se consideren en la evaluación puedes
                  comunicarte con tu asesor(a) y solicitarle tu pre-evaluación
                  de Jubilación Anticipada Ordinaria.
                </p>

                <div className="center-items">
                  <div
                    id="modal-contact"
                    className="whitecard-block contact-content"
                  >
                    <div className="row-info">
                      <img src={CastIcon} alt="call_icon" />{" "}
                      <p>{jaoAccessData.data.executive.name}</p>
                    </div>
                    <div className="row-info">
                      <img src={CallIcon} alt="call_icon" />{" "}
                      <p>{jaoAccessData.data.executive.cell_phone}</p>
                    </div>
                    <div className="row-info">
                      <img src={MailIcon} alt="call_icon" />{" "}
                      <p>
                        {shortStringWithPoints(
                          jaoAccessData.data.executive.email
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            }
            hasBtnPrimary={true}
            fnBtnPrimary={() => {
              setAlreadyContributeModal(!alreadyContributeModal);
            }}
            textBtnPrimary="Salir"
          />
        ) : (
          <CustomModal
            showModal={alreadyContributeModal}
            className="beauty-modal"
            backdrop={"static"}
            fnCloseIconBtn={() => {
              setAlreadyContributeModal(!alreadyContributeModal);
            }}
            title="¿Has realizado aportes como independiente?"
            description={
              <>
                <p className="mb-4">
                  Si quieres que se consideren en la evaluación puedes llamar al
                  contact center y solicitar por este canal tu pre-evaluación de
                  Jubilación Anticipada Ordinaria.
                </p>

                <button className="bnt-secondary-cellphone">
                  <img src={PhoneGreenIcon} alt="telefono-verde-icono" />
                  {jaoAccessData.data.call_center.number_format}
                </button>
              </>
            }
            hasBtnPrimary={true}
            fnBtnPrimary={() => {
              setAlreadyContributeModal(!alreadyContributeModal);
            }}
            textBtnPrimary="Salir"
          />
        )}
      </Col>
    </Row>
  );
}
